<template>
  <image-view-component>
    <template v-slot:form>
      <forgot v-if="!getSendCodeResetPasswordStatus" />

      <reset v-else-if="getResetPasswordStatus" />

      <div v-else class="success-reset-container">
        <h3>Pronto!</h3>

        <p>
          Sua senha foi <b>alterada com sucesso</b>, aperte o botão abaixo para ser redirecionado para a página inicial!
        </p>

        <button-component
          text="Ir para tela inicial"
          styles="primary"
          size="large"
          @click.native="toLogin"
          v-ripple="'rgba(255, 255, 255, 0.35)'"
        />
      </div>
    </template>
  </image-view-component>
</template>

<script>
  import ForgotPasswordForm from '@/components/ForgotPasswordForm';
  import ResetPasswordForm from '@/components/ResetPasswordForm';
  import Button from '@/components/Button';
  import ImageView from '@/components/base/ImageView';
  import { mapGetters } from 'vuex';

  export default {
    name: 'Reset',

    data() {
      return {
        sendCode: true,
        successReset: false
      };
    },

    computed: {
      ...mapGetters([
        'getSendCodeResetPasswordStatus',
        'getResetPasswordStatus'
      ])
    },

    components: {
      'forgot': ForgotPasswordForm,
      'reset': ResetPasswordForm,
      'button-component': Button,
      'image-view-component': ImageView,
    },

    mounted() {
      this.$store.commit('resetForgotPassoword');
    },

    methods: {
      toLogin() {
        this.$router.push('/').catch(() => {});
      }
    }
  };
</script>

<style lang="scss" scoped>
  .success-reset-container {
    h3 {
      text-align: center;
      margin-bottom: 1rem;
      color: #08B978;
    }

    p {
      text-align: center;
      max-width: 450px;
      margin-bottom: 2rem;
    }
  }
</style>