/* eslint-disable no-empty-pattern */
import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import { instance } from '@/config/index';
import VueCookie from 'vue-cookie';
import createPersistedState from "vuex-persistedstate";
import Service from '@/services/service';

Vue.use(VueCookie);
Vue.use(Vuex);

function initialState () {
  return {
    token: Vue.cookie.get('token'),
    clientId: '6da836641380f116b848a6b9e2e61021',
    clientSecret: '_BZxK8H0F41ZSs0yELxf',
    scope: 'lms',
    hasSpinner: false,
    hasError: false,
    disableToPayCard: true,
    disableUserData: true,
    disableCreditCardMethod: true,
    disableButton: true,
    creditCardMethod: true,
    isBoleto: false,
    isCreditCard: false,
    isPix: false,
    hideCreditCardSection: false,
    hasInstallments: false,
    firstStepStatus: 'wait',
    secondStepStatus: 'default',
    thirdBoletoStepStatus: 'default',
    thirdCreditCardStepStatus: 'default',
    pullUserData: false,
    currentUserData: {},
    repayBoletoToCreditCard: false,
    currentDebtId: '',
    debts: [],
    passwordEmail: '',
    passwordUsername: '',
    resetPassword: false,
    sendCodeResetPassword: false,
    qrCodeIsExpired: false,

    checkoutStatus: {
      isExpired: false,
      isPayment: true,
      isSuccessPayment: true,
      isPixPaymentError: false,
      isBoletoPayment: false
    },

    successPaymentCardData: {
      icon: 'icon-check',
      backgroundIcon: '#00d68f',
      title: 'Pagamento efetuado com sucesso!',
      transaction: {
        id: '',
        date: ''
      }
    },

    data: {
      email: '',
      postalCode: '',
      address: '',
      number: '',
      neighborhood: '',
      complement: '',
      city: '',
      stateCode: '',
      fullName: '',

      cardNumber: '',
      dateValidate: '',
      securityCode: '',
      taxpayerId: '',
      dateOfBirth: '',
      phone: '',

      installments: {
        numberOfInstallments: '',
        textOfInstallments: '',
      }
    }
  };
}

const actions = {
  setLogin({commit, state}, data) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'POST',
        url: process.env.VUE_APP_BASE_API_LMS_URL + '/token',
        data: JSON.stringify({
          client_id: state.clientId,
          client_secret: state.clientSecret,
          username: data.username,
          password: data.password,
          scope: state.scope,
          grant_type: 'password'
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(res => {
        let response = res.data;
        let token = response.access_token;
        let tokenExpiresIn = response.expires_in + 's';

        Vue.cookie.set('token', token, { expires: tokenExpiresIn }, true);

        commit('setToken', token);

        axios.defaults.headers.common['Authorization'] = token;

        resolve(res.data);
      }).catch(err => {
        reject(err.response);
      });
    });
  },

  requestResetPassword({state}, data) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'POST',
        url: process.env.VUE_APP_BASE_API_LMS_URL + '/resetPassword',
        data: JSON.stringify({
          client_id: state.clientId,
          username: data.username,
          scope: state.scope
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(res => {
        resolve(res.data);
      }).catch(err => {
        reject(err.response);
      });
    });
  },

  changePassword({state}, data) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'POST',
        url: process.env.VUE_APP_BASE_API_LMS_URL + '/changePassword',
        data: JSON.stringify({
          client_id: state.clientId,
          username: state.passwordUsername,
          new_password: data.newPassword,
          scope: state.scope,
          code: data.code,
          challenge_response: data.code,
          challenge_type: 'EmailCode'
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(res => {
        resolve(res);
      }).catch(err => {
        reject(err.response);
      });
    });
  },

  getDebts() {
    return new Promise((resolve, reject) => {
      instance().get('/consolidated-debts')
        .then(res => {
        let response = res.data;

        resolve(response);
      }).catch(err => {
        reject(err.response);
      });
    });
  },

  getDebtId({}, id) {
    return new Promise((resolve, reject) => {
      instance().get('/consolidated-debts/' + id)
        .then(res => {
          let response = res.data;

          resolve(response);
        }).catch(err => {
          reject(err.response);
        });
    });
  },

  getUserData({commit}) {
    return new Promise((resolve, reject) => {
      instance().get('/users/self')
        .then(res => {
          let response = res.data;

          commit('setCurrentUserData', response.data);

          resolve(response);
        }).catch(err => {
          reject(err.response);
        });
      });
  },

  setPayment({state}, data) {
    return new Promise((resolve, reject) => {
      instance().post('/consolidated-debts/' + state.currentDebtId + '/pay', data)
        .then(res => {
          let response = res.data;

          resolve(response);
        }).catch(err => {
          reject(err.response);
        });
    });
  },

  setLogout({commit}) {
    return new Promise((resolve) => {
      Vue.cookie.delete('token');
      delete axios.defaults.headers.common['Authorization'];
      commit('logout');
      resolve();
    });
  },
};

const getters = {
  getFirstName(state) {
    return Service.firstWord(state.currentUserData.name);
  },

  getCheckoutStatus(state) {
    return state.checkoutStatus;
  },

  getCurrentUserData(state) {
    return state.currentUserData;
  },

  getAllDebts(state) {
    return state.debts;
  },

  getToken(state) {
    return state.token;
  },

  getCurrentDebtId(state) {
    return state.currentDebtId;
  },

  getRadioPullUserData(state) {
    return state.pullUserData;
  },

  getRepayBoletoToCreditCard(state) {
    return state.repayBoletoToCreditCard;
  },

  getPasswordEmail(state) {
    return state.passwordEmail;
  },

  getSendCodeResetPasswordStatus(state) {
    return state.sendCodeResetPassword;
  },

  getQrCodeIsExpired(state) {
    return state.qrCodeIsExpired;
  },

  getResetPasswordStatus(state) {
    return state.resetPassword;
  },

  getSuccessPaymentCardData(state) {
    return state.successPaymentCardData;
  },
};

const mutations = {
  setAllDebts(state, debts) {
    state.debts = debts;
  },

  resetAllDebts(state) {
    state.debts = [];
  },

  setCurrentDebtId(state, id) {
    state.currentDebtId = id;
  },

  checkedRadioPullUserData(state) {
    state.pullUserData = true;
  },

  disableRadioPullUserData(state) {
    state.pullUserData = false;
  },

  resetCurrentDebtId(state) {
    state.currentDebtId = '';
  },

  setToken(state, token) {
    state.token = token;
  },

  setCurrentUserData(state, data) {
    state.currentUserData = data;
  },

  setBoleto(state) {
    state.isBoleto = true;
    state.disableToPayCard = false;
    state.disableUserData = false;
    state.hideCreditCardSection = true;
  },

  setPix(state) {
    state.isPix = true;
    state.disableToPayCard = false;
    state.disableUserData = false;
  },

  setCreditCard(state) {
    state.isCreditCard = true;
    state.disableToPayCard = false;
    state.hideCreditCardSection = false;
    state.hasInstallments = true;
  },

  setFirstStepStatus(state, status) {
    state.firstStepStatus = status;
  },

  setSecondStepStatus(state, status) {
    state.secondStepStatus = status;
  },

  setThirdBoletoStepStatus(state, status) {
    state.thirdBoletoStepStatus = status;
  },

  setThirdCreditCardStepStatus(state, status) {
    state.thirdCreditCardStepStatus = status;
  },

  resetState(state) {
    state.disableToPayCard = true;
    state.disableUserData = true;
    state.disableCreditCardMethod = true;
    state.disableButton = true;
    state.creditCardMethod = true;
    state.isBoleto = false;
    state.isCreditCard = false;
    state.isPix = false;
    state.hideCreditCardSection = false;
    state.hasInstallments = false;
    state.firstStepStatus = 'wait',
    state.secondStepStatus = 'default',
    state.thirdBoletoStepStatus = 'default',
    state.thirdCreditCardStepStatus = 'default';
    state.checkoutStatus = {
      isExpired: false,
      isPayment: true,
      isSuccessPayment: true,
      isPixPaymentError: false,
      isBoletoPayment: false
    };
  },

  resetCreditCardData(state) {
    state.data.cardNumber = '',
    state.data.dateValidate = '',
    state.data.securityCode = '',
    state.data.holderCardFullName = '',
    state.data.cpf = '',
    state.data.dateOfBirth = '',
    state.data.phone = '';
  },

  setQrCodeIsExpired(state, status) {
    state.qrCodeIsExpired = status;
  },

  resetPaymentData(state) {
    state.data = {
      fullName: '',
      email: '',
      postalCode: '',
      address: '',
      number: '',
      neighborhood: '',
      complement: '',
      city: '',
      stateCode: '',

      cardNumber: '',
      dateValidate: '',
      securityCode: '',
      holderCardFullName: '',
      taxpayerId: '',
      dateOfBirth: '',
      phone: '',

      installments: {
        numberOfInstallments: '',
        textOfInstallments: '',
      }
    };
  },

  enableButton(state) {
    state.disableButton = false;
  },

  disableUserDataForm(state) {
    state.disableUserData = true;
  },

  enableUserDataForm(state) {
    state.disableUserData = false;
  },

  enableCreditCardForm(state) {
    state.disableCreditCardMethod = false;
  },

  disableCreditCardForm(state) {
    state.disableCreditCardMethod = true;
  },

  disableButton(state) {
    state.disableButton = true;
  },

  disableToPayCard(state) {
    state.disableToPayCard = true;
  },

  enableRepayBoletoToCreditCard(state) {
    state.repayBoletoToCreditCard = true;
  },

  disableRepayBoletoToCreditCard(state) {
    state.repayBoletoToCreditCard = false;
  },

  changeSpinnerStatus(state, status) {
    state.hasSpinner = status;
  },

  changeErrorStatus(state, status) {
    state.hasError = status;
  },

  setPasswordEmail(state, email) {
    state.passwordEmail = email;
  },

  setSendCodeResetPasswordStatus(state, status) {
    state.sendCodeResetPassword = status;
  },

  setResetPasswordStatus(state, status) {
    state.resetPassword = status;
  },

  setPasswordUsername(state, username) {
    state.passwordUsername = username;
  },

  resetForgotPassoword(state) {
    state.passwordEmail = '';
    state.passwordUsername = '';
    state.resetPassword = false;
    state.sendCodeResetPassword = false;
  },

  logout(state) {
    const inital = initialState();

    Object.keys(inital).forEach(key => {
      state[key] = inital[key];
    });
  }
};

const store = new Vuex.Store({
  state: initialState(),
  actions,
  getters,
  mutations,
  plugins: [
    createPersistedState()
  ]
});

export default store;