import { cpf } from 'cpf-cnpj-validator';
import moment from 'moment';

export default {
  isTaxpayerId(inputCpf) {
    if (inputCpf != null) {
      let strCpf  = inputCpf.replace(/\.|-/g,"");

      return cpf.isValid(strCpf);
    }
  },

  formatDateToBr(date) {
    return date.split('-').reverse().join('/');
  },

  isPostalCode(postalCode) {
    if (postalCode != null) {
      let valueChar = postalCode.replace(/[^0-9]/g, '');

      return valueChar.length === 8;
    }
  },

  isMinLength(str, min) {
    return str.length >= min;
  },

  isDate(date) {
    if (date != null && date.length == 5) {
      let currentMonth = new Date().getMonth() + 1;
      let currentYear = new Date().getFullYear().toString().substr(-2);

      let validateMonth = date.toString().substr(0, 2);
      let validateYear = date.toString().substr(-2);

      if ((validateMonth >= currentMonth && validateYear >= currentYear)
         || (validateYear > currentYear)) {
        return moment(date, "MM-YY").isValid();
      }
    } else {
      return false;
    }
  },

  isDateOfBirth(date) {
    if (date != null && date.length == 10) {
      return moment(date, "DD-MM-YYYY").isValid();
    }
  },

  isEmail(email) {
    if (email != null) {
      let regEmail = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;

      return email.match(regEmail);
    }
  },

  toUpperCase(str) {
    return str.toUpperCase();
  },

  maxLength(value, max) {
    return value.length < max;
  },

  isFullName(name) {
    if (name != null) {
      if (name.indexOf(' ') >= 0) {
        let regName = /^[\p{L}'][ \p{L}'-]*[\p{L}]$/u;

        if (name.match(regName)) return true;
      }
    } else {
      return false;
    }
  },

  /* Caution: Changing a regex, you must change it everywhere */
  isValidCard(number) {
    if (number != null) {
      let regCards = {
        visa: /^4[0-9]{12}(?:[0-9]{3})/,
        master: /^5[1-5]|^2(2(2[1-9]|[3-9])|[3-6]|7([01]|20))/,
        elo: /^4011(78|79)|^43(1274|8935)|^45(1416|7393|763(1|2))|^50(4175|6699|67[0-6][0-9]|677[0-8]|9[0-8][0-9]{2}|99[0-8][0-9]|999[0-9])|^627780|^63(6297|6368|6369)|^65(0(0(3([1-3]|[5-9])|4([0-9])|5[0-1])|4(0[5-9]|[1-3][0-9]|8[5-9]|9[0-9])|5([0-2][0-9]|3[0-8]|4[1-9]|[5-8][0-9]|9[0-8])|7(0[0-9]|1[0-8]|2[0-7])|9(0[1-9]|[1-6][0-9]|7[0-8]))|16(5[2-9]|[6-7][0-9])|50(0[0-9]|1[0-9]|2[1-9]|[3-4][0-9]|5[0-8]))/,
        hiper: '^606282|^637095|^637599|^637568',
        discover: /^6(?:011|5[0-9]{2})[0-9]{12}/,
        jcb: /^(?:2131|1800|35\d{3})\d{11}/,
        banese: /^636117/,
        amex: /^3[47][0-9]{13}/,
        aura: /^((?!504175))^((?!5067))(^50[0-9])/
      };

      for (var card in regCards) if (number.match(regCards[card])) return card;

      return false;
    }
  },

  isEmptyArr(arr) {
    return arr.length === 0;
  },

  removeCharacters(str) {
    return str.replace(/[^0-9]/g, '');
  },

  firstWord(str) {
    return str.split(' ')[0];
  },

  isValidPhone(phone) {
    if (phone.length >= 18 && phone.length <= 19) {
      let regName = /^(?:\+)[0-9]{2}\s?(?:\()[0-9]{2}(?:\))\s?[0-9]{4,5}(?:-)[0-9]{4}$/;

      return phone.match(regName);
    }

    return false;
  },

  lastWord(str) {
    let strSplit = str.split(' ');
    let strLength = strSplit.length - 1;

    return strSplit[strLength];
  },

  discountAmount(value, percent) {
    let valueDiscount = this.percentage(value, percent);

    return parseFloat(valueDiscount) < parseFloat(value) ? parseFloat(value) - parseFloat(valueDiscount) : 0;
  },

  percentage(value, percent) {
    return ((percent / 100) * value).toFixed(2);
  },

  formatPercentage(percent) {
    return parseInt(percent) + '%';
  },

  formatBrlAmount(amount) {
    return parseFloat(amount).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  },

  formatDate(fullDate) {
    let date = fullDate.split(' ')[0];

    return this.formatDateToBr(date);
  },

  dateFormat(date, format = 'DD/MM/YYYY') {
    return date != null ? moment(date).format(format) : null;
  },

  hasSpecialCharacters(str) {
    const regex = /\W|_/;

    return regex.test(str);
  },

  formatDebt(item) {
    return {
      debtId: item.Codigo,
      contractId: item.CodigoContrato,
      paidDate: this.dateFormat(item.DataRecebimento, 'DD/MM/YYYY'),
      personId: item.CodigoPessoa,
      formattedExpiresData: this.formatDate(item.DataExpiracao),
      expiresDate: item.DataExpiracao,
      formattedDescription: item.Descricao == null ? 'Nenhuma descrição para essa proposta!' : item.Descricao,
      description: item.Descricao,
      formattedDiscountPercentage: this.formatPercentage(item.PercentualDesconto),
      discountPercentage: item.PercentualDesconto,
      formattedDiscountAmount: this.formatBrlAmount(item.ValorTotal),
      discountAmount: item.ValorTotal,
      formattedAmount: this.formatBrlAmount(item.Valor),
      amount: item.Valor,
      formattedAmountDiscout: this.formatBrlAmount(item.ValorDesconto),
      amountDiscout: item.ValorDesconto,
      isExpired: item.Expirado,
      linkBoleto: item.transaction && item.transaction.payment.boleto ? item.transaction.payment.boleto.url : null,
      transactionId: item.TransactionId,
      paymentStatus: this.formatPaymentStatus(item.StatusPagamento),
      debtStatus: this.formatDebtStatus(item)
    };
  },

  formatDebtStatus(item) {
    let status = '';

    if (item.Expirado) {
      status = 'Expired';
    } else {
      status = this.formatPaymentStatus(item.StatusPagamento);
    }

    return status;
  },

  formatPaymentStatus(status) {
    return this.toUpperCase(status) == 'PAGO' ? 'Paid' : 'Pending';
  },
};