<template>
  <div class="payment-container">
    <div class="payment-method-container">
      <div class="payment-method">
        <h1>Forma de pagamento</h1>

        <div class="value-container">
          <div class="column">
            <div class="value-output">
              <label>Valor total</label>

              <div class="value">
               {{ data.formattedDiscountAmount }}
              </div>
            </div>
          </div>

          <div v-if="hasInstallments" class="column">
            <div class="value-input">
              <label>Número de parcelas</label>

              <div class="value">
                <select :style="{ 'background': arrowDown }" v-model="numberOfInstallments">
                  <option class="text" hidden selected value="">
                    Escolha as parcelas
                  </option>

                  <option v-for="item in installments" :value="item" :key="item.id">
                    {{ item.option }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import Arrow from '@/assets/icons/arrow-down-select.svg';

  export default {
    name: 'ToPayCard',

    data() {
      return {
        arrow: Arrow,
        maxInstallments: 12,
        minValueInstallment: 40,
        installments: [],
        numberOfInstallments: ''
      };
    },

    props: {
      data: {
        type: Object,
        default() {
          return {
            formattedDiscountAmount: 'R$ 400',
            discountAmount: 400
          };
        }
      }
    },

    watch: {
      'numberOfInstallments'() {
        this.paymentData.installments.numberOfInstallments = this.numberOfInstallments.id;
        this.paymentData.installments.textOfInstallments = this.numberOfInstallments.option;

        this.$store.commit('setSecondStepStatus', 'check');
        this.$store.commit('setThirdCreditCardStepStatus', 'wait');
        this.$store.commit('enableCreditCardForm');
        this.selected();
      },

      'isBoleto'() {
        if (this.isBoleto) {
          this.numberOfInstallments = '';
        }
      }
    },

    computed: {
      ...mapState({
        hasInstallments: state => state.hasInstallments,
        isBoleto: state => state.isBoleto,
        paymentData: state => state.data
      }),

      arrowDown() {
        return 'url('+ `${this.arrow}` + ') #FFF no-repeat 92%';
      }
    },

    mounted() {
      if(this.data.discountAmount > this.minValueInstallment) {
        for (var i = 0; i < this.maxInstallments; i++) {
          let index = i + 1;
          let valuePerInstallment = this.data.discountAmount/index;

          if (valuePerInstallment >= this.minValueInstallment) {
            let formattedValueInstallment = this.$service.formatBrlAmount(valuePerInstallment);

            this.installments.push({
              id: index,
              amount: this.data.discountAmount,
              option: index + ' x de ' + formattedValueInstallment
            });
          }
        }
      } else {
        this.installments.push({
          id: 1,
          amount: this.data.discountAmount,
          option: 1 + ' x de ' + this.data.formattedDiscountAmount
        });
      }
    },

    methods: {
      selected() {
        this.$store.commit('enableUserDataForm');
      },

      minValueOnArray(array) {
        return Math.min.apply(Math, array);
      }
    }
  };
</script>

<style lang="scss" scoped>
  .payment-container {
    display: flex;

    .payment-method-container {
      width: 100%;

      .payment-method {
        background: #efefef;
        width: 100%;
        padding: 1.5rem;
        border-radius: 1.5rem;

        h1 {
          font-size: 1.2rem;
          display: inline;
        }

        .value-container {
          margin-top: 1.5rem;
          display: flex;

          .value-output {
            margin-right: 1.5rem;

            .value {
              padding: 1rem;
              width: 14rem;
              background: #dadada;
              border-radius: 0.5rem;
              margin-top: 0.5rem;
              font-weight: 700;
              height: 48px;
              display: flex;
              align-items: center;
            }
          }

          .value-input {
            flex-direction: column;

            select {
              -webkit-appearance: none;
              -moz-appearance: none;
              margin-top: 0.5rem;
              width: 14rem;
              font-size: 0.9rem;
              border: none;
              border-radius: 0.5rem;
              border: 2px solid #e3e3e3;
              padding: 0 1rem;
              appearance: none;
              background-position-x: 90%;
              outline: 0;
              height: 48px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 1199px) {
    .payment-container {
      .payment-method-container {
        .payment-method {
          h1 {
            font-size: 1.2rem;
          }
        }
      }
    }
  }

  @media (max-width: 1120px) {
    .payment-container {
      .payment-method-container {
        .payment-method {
          .value-container {
            flex-direction: column;

            .value-output {
              margin: 0;
            }

            .value-input {
              margin-top: 1rem;
            }
          }
        }
      }
    }
  }

  @media (max-width: 678px) {
    .payment-container {
      .payment-method-container {
        .payment-method {
          padding: 2rem 1rem;
        }
      }
    }
  }
</style>