import axios from 'axios';
import Vue from 'vue';
import router from '@/router';
import store from '@/store';
import * as Sentry from '@sentry/vue';

export const instance = () => {
  const instance = axios.create({
    baseURL: process.env.VUE_APP_BASE_API_ERP_URL,
    timeout: 10000,
    headers: {
      'Content-Type': 'application/json',
      'X-Access-Token': Vue.cookie.get('token')
    }
  });

  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 401) {
        Vue.cookie.set('token', null);
        store.dispatch('setLogout');
        router.push('/').catch(() => {});
      }

      Sentry.configureScope(scope => {
        scope.setExtra('endpoint', error.response.config.url);
        scope.setExtra('full_data', JSON.stringify(error.response.data));
        Sentry.captureException(error);
      });

      return Promise.reject(error);
    }
  );

  return instance;
};

export default instance;