<template>
  <div class="option-card" :class="{ 'disabled': isDisabled || isExpired }">
    <div class="header">
      Contrato

      <b>{{ data.contractId }}</b>
    </div>

    <div class="informations">
      <div class="two-columns">
        <div class="value-container">
          Valor com desconto

          <div class="value">
            {{ data.formattedDiscountAmount }}
          </div>

          <div class="discount">
            {{ data.formattedAmount }} com {{ data.formattedDiscountPercentage }} de desc.
          </div>
        </div>

        <div class="description">
          Descrição

          <div class="text">
            {{ data.formattedDescription }}
          </div>
        </div>
      </div>

      <div class="button-container">
        <button-component
          :text="buttonText"
          v-ripple="'rgba(255, 255, 255, 0.35)'"
          styles="primary"
          size="small"
          :isDisabled="isExpired || isDisabled"
          :hasLoader="hasRequest"
          @click.native="toCheckout"
        />

        <p v-if="!!paidDate" class="paid">
          Pago em

          <b>
            {{ paidDate }}
          </b>
        </p>

        <p v-else-if="isExpired" class="expired">
          Expirado em

          <b>
            {{ data.formattedExpiresData }}
          </b>
        </p>

        <p v-else>
          Valido até

          <b>
            {{ data.formattedExpiresData }}
          </b>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
  import Button from '@/components/Button';

  export default {
    name: 'OptionsCard',

    components: {
      'button-component': Button
    },

    data() {
      return {
        disabledButton: false,
        paidDate: '',
        isDisabled: false,
        isExpired: false,
        buttonText: 'Renegociar',
      };
    },

    props: {
      data: {
        type: Object,
        required: true
      },

      hasRequest: {
        type: Boolean,
        required: false
      }
    },

    watch: {
      'hasRequest'() {
        this.isDisabled = this.hasRequest;
      }
    },

    mounted() {
      if(this.$service.toUpperCase(this.data.paymentStatus) == 'PAID') {
        this.buttonText = 'Pago';
        this.isDisabled = true;
        this.paidDate = this.data.paidDate;
      } else if(this.$service.toUpperCase(this.data.paymentStatus) == 'PENDING' && this.data.linkBoleto != null) {
        this.buttonText = 'Ver boleto';
      } else if (this.data.isExpired) {
        this.buttonText = 'Expirado';
        this.isExpired = this.data.isExpired;
      } else {
        this.buttonText = 'Renegociar';
      }
    },

    computed: {
      deviceType() {
        return this.width >= 678 ? 'Desktop' : 'Mobile';
      }
    },

    methods: {
      toCheckout() {
        this.isDisabled = true;

        if(this.isExpired || this.isDisabled) {
          this.$store.commit('setCurrentDebtId', this.data.debtId);
        }

        this.$router.push({ path: '/checkout'}).catch(() => {});
      }
    },
  };
</script>

<style lang="scss" scoped>
  .default-data-container {
    .default-data {
      height: 180px;
      width: 100%;
      margin-bottom: 1rem;
      border-radius: 20px;
    }
  }

  .option-card {
    background: #efefef;
    margin-bottom: 2rem;
    border-radius: 20px;
    padding: 1rem 2rem;

    .header {
      font-size: 18px;
      margin-bottom: 1rem;

      b {
        color: #AA1428;
      }
    }

    .informations {
      display: flex;

      .two-columns {
        display: flex;

        .value-container {
          .value {
            margin-top: 0.5rem;
            font-size: 20px;
            font-weight: 600;
            background: #DADADA;
            padding: 0.5rem 1rem;
            border-radius: 8px;
            color: #333;
          }

          .discount {
            font-size: 12px;
            margin-top: 0.5rem;
            text-align: center;
            color: #6D6F6F;
          }
        }

        .description {
          width: 20rem;
          margin-left: 2rem;
          margin-right: 2rem;

          .text {
            font-size: 14px;
            margin-top: 0.5rem;
            color: #6D6F6F;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
          }
        }
      }

      .button-container {
        margin-left: auto;

        p {
          font-size: 14px;
          color: #6D6F6F;
          margin-top: 0.5rem;

          &.expired {
            font-weight: 700;
            color: #AA1428;
          }

          &.paid {
            font-weight: 700;
            color: #046d47;
          }
        }
      }
    }

    &.disabled {
      opacity: 0.6;
      user-select: none;
      cursor: not-allowed;

      .header {
        b {
          color: #333;
        }
      }
    }
  }

  @media (max-width: 820px) {
    .option-card {
      .informations {
        flex-direction: column;

        .two-columns {
          display: flex;

          .value-container {
            width: 18rem;
          }

          .description {
            width: 100%;
            margin-right: 0;
          }
        }

        .button-container {
          margin-top: 1.5rem;
          margin-left: 0;

          p {
            text-align: center;
          }
        }
      }
    }
  }

  @media (max-width: 580px) {
    .option-card {
      padding: 1.5rem;

      .informations {
        .two-columns {
          flex-direction: column;

          .value-container {
            width: 100%;
          }

          .description {
            margin-left: 0;
            margin-top: 1.5rem;
          }
        }
      }
    }
  }
</style>
