<template>
  <div class="status-container">
    <div class="status" :class="status">
      <div class="icon">
        <i v-if="status == 'check'" class="icon-check"/>

        <div v-else>
          {{ step }}
        </div>
      </div>
    </div>

    <div v-if="hasLine" class="vertical-line-container">
      <div class="vertical-line"></div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'status-component',

    props: {
      status: {
        type: String,
        default: 'default'
      },

      hasLine: {
        type: Boolean,
        default: true
      },

      step: {
        type: String,
        default: 'n'
      }
    }
  };
</script>

<style lang="scss" scoped>
  .status-container {
    margin-right: 1rem;
    margin-top: 1.5rem;

    .status {
      width: 35px;
      height: 35px;
      border-radius: 3rem;
      color: #FFF;

      .icon {
        justify-content: center;
        align-items: center;
        font-weight: bold;
        display: flex;
        height: 100%;
      }

      &.check {
        background: #00d68f;
      }

      &.default {
        background: #a5a5a5;
      }

      &.wait {
        background: #ff9c00;
      }
    }

    .vertical-line-container {
      height: 100%;
      margin-top: 0.5rem;
      justify-content: center;
      display: flex;

      .vertical-line {
        border-left: 1px solid #DADADA;
        height: 100%;
      }
    }
  }

  @media (max-width: 1045px) {
    .status-container {
      margin-right: 0.5rem;

      .status {
        width: 20px;
        height: 20px;

        .icon {
          font-size: 0.8rem;
        }
      }
    }
  }
</style>
