<template>
  <div class="qr-code-card-component" ref="component">
    <div class="qr-code-card-container" :class="{ 'expire': getQrCodeIsExpired || hasQrCodeRequest }" @click="retryOrCopy">
      <div class="logo-container">
        <img src="@/assets/images/logo-impay.svg">
      </div>

      <div class="qr-code-container">
        <vue-qrcode
          class="qr-code"
          :value="emvValue"
        />
      </div>

      <div v-if="!isCopied" class="copy-container">
        <i class="icon-copy-outline" />

        Pix copia e cola
      </div>

      <div v-else class="copied-container">
        <i class="icon-check-circle-outline" />

        Código copiado
      </div>

      <div class="expired-container">
        <spinner-component
          v-if="hasQrCodeRequest"
          :styles="buttonSpinner"
        />

        <div v-if="getQrCodeIsExpired" class="expired-content">
          <i class="icon-refresh" />

          Recarregar
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapState } from 'vuex';
  import VueQrcode from 'vue-qrcode';
  import Spinner from '@/components/Spinner';

  export default {
    name: 'QRCodeCard',

    data() {
      return {
        isCopied: false,
        buttonSpinner: {
          size: '5px',
          color: '#ff9c00',
          borderSize: '1.3em',
        }
      };
    },

    props: {
      emvValue: {
        type: String,
        default: 'Default'
      },

      hasQrCodeRequest: {
        type: Boolean,
        default: false
      }
    },

    components: {
      VueQrcode,
      'spinner-component': Spinner
    },

    computed: {
      ...mapState({
        qrCodeIsExpired: state => state.qrCodeIsExpired
      }),

      ...mapGetters([
        'getQrCodeIsExpired'
      ])
    },

    methods: {
      retryOrCopy() {
        if (!this.getQrCodeIsExpired && !this.hasQrCodeRequest) {
          this.isCopied = true;

          setTimeout(() => {
            this.isCopied = false;
          }, 1500);

          this.copyToClipboard(this.emvValue);
        } else {
          this.$store.commit('setQrCodeIsExpired', false);
        }
      },

      copyToClipboard(str) {
        var currentElement = document.createElement('textarea');
        currentElement.value = str;
        currentElement.setAttribute('readonly', '');
        currentElement.style = { position: 'absolute', left: '-9999px' };
        document.body.appendChild(currentElement);
        currentElement.select();
        document.execCommand('copy');
        document.body.removeChild(currentElement);
      }
    }
  };
</script>

<style lang="scss" scoped>
  .qr-code-card-component {
    display: flex;

    .qr-code-card-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background: #FFF;
      padding: 1rem;
      box-shadow: 0px 0px 15px rgb(0 0 0 / 5%);
      cursor: pointer;
      width: 250px;
      min-width: 230px;

      &.expire > *:not(.expired-container) {
        -webkit-filter: blur(5px);
        -moz-filter: blur(5px);
        -o-filter: blur(5px);
        -ms-filter: blur(5px);
        filter: blur(5px);
      }

      .expired-container {
        position: absolute;
        color: #ff9c00;
        font-weight: 600;

        .expired-content {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;

          i {
            font-size: 2rem;
            margin-bottom: 0.5rem;
          }
        }

        &:hover {
          color: #df8b04;
        }
      }

      .logo-container {
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 90px;
        }
      }

      .qr-code-container {
        min-width: 200px;
        min-height: 200px;
      }

      .qr-code {
        width: 200px;
        padding: 0.5rem;
      }

      .copy-container {
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        color: #ff9c00;;
        padding: 0 1rem;

        i {
          margin-right: 0.5rem;
        }
      }

      .copied-container {
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        color: #08B978;

        i {
          margin-right: 0.5rem;
        }
      }
    }
  }

  @media (max-width: 480px) {
    .qr-code-card-component {
      .qr-code-card-container {
        width: 100%;
      }
    }
  }
</style>