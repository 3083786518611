<template>
  <ValidationObserver
    tag="div"
    class="form"
    ref="forgotPasswordForm"
    v-slot="{ passes }"
  >
    <form @submit.prevent="passes(requestReset)">
      <h3>Esqueci minha senha</h3>

      <input-field
        label="Usuário"
        vid="cpf"
        class="input"
        placeholder="CPF ou código de acesso"
        :mask="isTaxpayerId ? '###.###.###-##' : null"
        rules="required"
        @keydown.native.space.prevent
        v-model="username"
      />

      <button-component
        text="Enviar código"
        styles="primary"
        :hasLoader="hasRequest"
        :isDisabled="hasRequest"
        size="large"
        v-ripple="'rgba(255, 255, 255, 0.35)'"
      />

      <div class="back-button-container">
        <router-link
          tag="p"
          to="/"
          @click="backToLogin"
        >
          Voltar para login
        </router-link>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
  import Button from '@/components/Button';
  import InputField from '@/components/InputField';
  import { ValidationObserver } from 'vee-validate';

  export default {
    name: 'ForgotPasswordForm',

    data() {
      return {
        username: '',
        hasRequest: false,
        isTaxpayerId: false
      };
    },

    components: {
      ValidationObserver,
      'button-component': Button,
      'input-field': InputField,
    },

    mounted() {
      this.$store.commit('resetForgotPassoword');
    },

    watch: {
      username(value) {
        this.isTaxpayerId = value.length >= 11;
      }
    },

    methods: {
      requestReset() {
        this.hasRequest = true;

        let data = {
          username: this.$service.removeCharacters(this.username)
        };

        this.$store.dispatch('requestResetPassword', data)
          .then(res => {
            let regEmail = /<([^>]+)>/;
            this.$store.commit('setPasswordEmail', regEmail.exec(res.message)[1]);
            this.$store.commit('setSendCodeResetPasswordStatus', true);
            this.$store.commit('setResetPasswordStatus', true);
            this.$store.commit('setPasswordUsername', data.username);
          })
          .catch(() => {
            this.$refs.forgotPasswordForm.setErrors({
              cpf: 'Erro ao enviar o código'
            });
          })
          .finally(() => {
            this.hasRequest = false;
          });
      },

      backToLogin() {
        this.$store.commit('resetForgotPassoword');

        this.$router.push('/').catch(() => {});
      }
    }
  };
</script>

<style lang="scss" scoped>
  .form {
    color: #333;
    width: 100%;
    max-width: 25rem;

    form {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      display: flex;
      width: 100%;

      h3 {
        margin-bottom: 1rem;
      }

      .email-container {
        margin-bottom: 1.5rem;
        margin-top: 1rem;
        width: 100%;

        p {
          text-align: unset;
          margin-bottom: unset;
        }
      }

      p {
        text-align: center;
        margin-bottom: 2rem;
      }

      .input {
        margin-bottom: 1rem;
      }

      .back-button-container {
        margin-top: 2rem;
        display: flex;

        p {
          margin-left: auto;
          color: #0052CC;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }

  @media (max-width: 1200px) {
    .form {
      width: 60%;
    }
  }

  @media (max-width: 768px) {
    .form {
      width: 100%;
    }
  }
</style>