<template>
  <button
    :class="isDisabled ? `${styles} disabled ${size}` : `${styles} ${size}`"
    :style="styleObject"
    :disabled="isDisabled"
  >
    <div v-if="hasLoader" class="spinner" :class="{ 'disabled': isDisabled }"></div>

    <div v-else class="content" :class="!!iconLeft || !!iconRigth ? 'no-icon' : ''">
      <i v-if="!!iconLeft" :class="iconLeft"/>

      {{text}}

      <i v-if="!!iconRigth" :class="iconRigth"  />
    </div>
  </button>
</template>

<script>
  export default {
    name: 'Button',

    props: {
      text: {
        type: String,
        default: 'text',
			},

      isDisabled: {
        type: Boolean,
        default: false,
      },

      hasLoader: {
        type: Boolean,
        default: false,
      },

      iconLeft: {
        type: String,
        required: false,
      },

      iconRigth: {
        type: String,
        required: false,
      },

      size: {
        type: String,
        default: '',
      },

      buttonTextColor: {
        type: String,
        default: '#FF9C00'
      },

      buttonHoverColor: {
        type: String,
        default: 'rgba(255, 156, 0, 0.1)'
      },

      styles: {
        type: String,
        default: 'primary',
      }
    },

    computed: {
      styleObject: function() {
        return {
          'color': this.buttonTextColor,
          '--color-hover': this.buttonHoverColor
        };
      }
    }
  };
</script>

<style lang="scss" scoped>
	button {
		width: 100%;
    font-size: 16px;
    font-weight: 500;
		min-height: 40px;
    max-height: 48px;
		border: none;
    border-radius: 6px;
    outline: none;
    cursor: pointer;

    justify-content: center;
    align-items: center;
    display: flex;

    &.primary {
      color: #FFF !important;
      background: #FF9C00;

      &:hover {
        background-color: #f99400;
      }

      &:active {
        background-color: #f99400;
      }
    }

    &.secondary {
      width: auto;
      background: transparent;

      &:hover {
        background: var(--color-hover);
      }

      &:active {
        background: var(--color-hover);
      }
    }

    &.ghost {
      color: #f5a939 !important;
      background: transparent;
      border: 1px solid #f5a939;

      &:hover {
        color: #f5a939;
        background: rgba(255, 156, 0, 0.1);
        border: 1px solid #f5a939;
      }

      &:active {
        color: #f5a939;
        background: rgba(255, 156, 0, 0.1);
        border: 1px solid #f5a939;
      }
    }

    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
      background: #DADADA;
      color: #333 !important;

      &:hover {
        background: #DADADA;
      }
    }

    &.large {
      height: 48px;
    }

    &.medium {
      height: 40px;
    }

    &.small {
      height: 32px;
    }

    .content {
      display: flex;
      font-weight: 600;

      i {
        margin-right: 10px;
        margin-left: 10px;
      }

      &.no-icon {
        margin-right: 10px;
      }
    }

    .spinner {
      font-size: 7px;
      border-left: 0.6em solid #ffffff;
      border-top: 0.6em solid rgba(255, 255, 255, 0.2);
      border-right: 0.6em solid rgba(255, 255, 255, 0.2);
      border-bottom: 0.6em solid rgba(255, 255, 255, 0.2);
      animation: spinner 1.1s infinite linear;

      &, &:after {
        border-radius: 50%;
        width: 3.5em;
        height: 3.5em;
      }

      &.disabled {
        border-left: 0.6em solid #333;
      }
    }

    @keyframes spinner {
      0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }

      100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
	}
</style>