<template>
  <div class="radio-container">
    <label class="radio">
      <input
        type="radio"
        :name="name"
        v-model="status"
        :value="isChecked"
      />

      <span class="circle"></span>
    </label>

    <label class="label">{{ label }}</label>
  </div>
</template>

<script>
  export default {
    name: 'Radio',

    data() {
      return {
        status: true,
      };
    },

    props: {
      isChecked: {
        type: Boolean,
        default: true,
      },

      name: {
        type: String,
        required: ''
      },

      label: {
        type: String,
        required: false,
      }
    },

    watch: {
      status() {
        this.$emit('input', true);
      },
    }
  };
</script>

<style lang="scss" scoped>
  .radio-container {
    height: 23px;
    display: flex;
    margin-bottom: 1rem;

    .radio {
      display: inline-block;
      position: relative;
      cursor: pointer;

      input {
        display: none;

        &:checked + .circle {
          border: 3px solid #ff9c00;
          background: #FFF
        }

        &:checked + .circle:after {
          content: "";
          height: 8px;
          width: 8px;
          background: #ff9c00;
          position: absolute;
          border-radius: 50%;
          left: 3px;
          top: 3px;
        }
      }

      .circle {
        display: inline-block;
        width: 20px;
        height: 20px;
        margin-top: 1px;
        background: #FFF;
        position: absolute;
        left: 0;
        top: 0;
        border-radius: 50%;
        border: 3px solid #ff9c00;
      }
    }

    .label {
      margin-left: 2rem;
      font-weight: 300;
      font-size: 14px;
      align-items: center;
      display: flex;
    }
  }
</style>