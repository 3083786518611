<template>
  <div v-if="isVisible" class="notification-card-container" :style="{'background': data.color}">
    <div class="card">
      <p>{{ data.text }}</p>

       <i class="icon-close" @click="hideCard"/>
    </div>

    <div class="progress-bar"></div>
  </div>
</template>

<script>
  export default {
    name: 'AlertCard',

    data() {
      return {
        isVisible: true,
      };
    },

    props: {
      data: {
        type: Object,
        default() {
          return {
            text: 'Dados editados com sucesso.',
            color: '#08B978'
          };
        }
      }
    },

    mounted() {
      setTimeout(() => {
        this.hideCard();
      }, 1800);
    },

    methods: {
      hideCard() {
        this.isVisible = false;
      }
    }
  };
</script>

<style lang="scss" scoped>
  .notification-card-container {
    width: 250px;
    position: absolute;
    right: 0;
    margin-top: 100px;
    z-index: 1000;

    .card {
      display: flex;
      min-height: 4rem;
      padding: 1rem;

      p {
        color: #FFFFFF;
        margin-right: 1.5rem;
      }

      i {
        color: #FFFFFF80;
        margin-left: auto;
      }
    }

    .progress-bar {
      height: 6px;
      animation: progress 2s;
      background: #FFFFFF64;
    }

    @keyframes progress {
      from {
        width: 0%;
      }

      to {
        width: 100%;
      }
    }
  }
</style>