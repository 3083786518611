<template>
  <div class="card">
    <div class="options-container">
      <button-component
        class="button-container"
        v-ripple="'rgba(255, 139, 0, 0.2)'"
        styles="secondary"
        iconLeft="icon-credit-card"
        text="Pagar com Cartão ou Pix"
        @click.native="toCreditCardPayment"
      />

      <button-component
        class="button-container"
        v-ripple="'rgba(255, 139, 0, 0.2)'"
        styles="secondary"
        iconLeft="icon-print"
        text="Imprimir"
        @click.native="printBoleto"
      />
    </div>

    <div class="boleto-container">
      <iframe
        ref="boleto"
        :src="data.boletoUrl"
        allowfullscreen
        webkitallowfullscreen
        mozallowfullscreen
        height="800"
        width="100%"
        frameborder="0"
        marginheight="0"
        marginwidth="0"
      >
      </iframe>
    </div>
  </div>
</template>

<script>
  import Button from '@/components/Button';

  export default {
    name: 'BoletoCard',

    components: {
      'button-component': Button
    },

    props: {
      data: {
        type: Object,
        default() {
          return {
            boletoUrl: '',
          };
        }
      }
    },

    methods: {
      toCreditCardPayment() {
        this.$store.commit('enableRepayBoletoToCreditCard');
      },

      printBoleto() {
        window.open(this.data.boletoUrl, '_blank');
      }
    }
  };
</script>

<style lang="scss" scoped>
  .card {
    background: #FFFFFF;
    box-shadow: 0px 0px 15px rgb(0 0 0 / 5%);
    padding: 2rem;
    flex-direction: column;
    border-radius: 1.5rem;
    margin-bottom: 3rem;

    .options-container {
      display: flex;

      .button-container {
        &:last-child {
          margin-left: auto;
        }
      }
    }

    .boleto-container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 3rem;
    }
  }

  @media (max-width: 520px) {
    .card {
      padding: 3rem 1rem;
    }
  }

  @media(max-width: 420px) {
    .card {
      .options-container {
        flex-direction: column;

        .button-container {
          &:last-child {
            margin-left: unset;
            margin-top: 1rem;
          }
        }
      }
    }
  }
</style>