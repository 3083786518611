<template>
  <ValidationProvider
    tag="div"
    :vid="vid"
    :name="name"
    :rules="rules"
    class="static-input-container"
    :class="styles"
    v-slot="{errors, required}"
  >
    <div>
      <p>
        {{ label }}

        <span>
          {{ required ? '*' : '' }}
        </span>
      </p>
    </div>

    <div class="input-container">
      <input
        v-if="hasFlag"
        :type="inputType"
        :value="value"
        :placeholder="placeholder"
        v-mask="mask"
        v-model="innerValue"
        autocomplete="off"
        :maxlength="maxLength"
        :disabled="disable"
        :class="{ 'error': !!errors[0] }"
        :style="{ 'background': flagImage }"
      />

      <input
        v-else-if="mask == 'phone'"
        :type="inputType"
        :value="value"
        :placeholder="placeholder"
        v-mask="['+55 (##) ####-####', '+55 (##) #####-####']"
        v-model="innerValue"
        autocomplete="off"
        :class="{ 'error': !!errors[0] }"
        :disabled="disable"
        :maxlength="maxLength"
      />

      <input
        v-else
        :type="inputType"
        :value="value"
        :placeholder="placeholder"
        v-model="innerValue"
        v-mask="mask || nomask"
        autocomplete="off"
        :disabled="disable"
        :maxlength="maxLength"
        :class="{ 'passwd': inputType == 'password', 'error': !!errors[0] }"
      />

      <div v-if="isPassword() && !!innerValue" class="icon-container" @click="toggleVisibility">
        <i :class="visibility ? 'icon-eye-slash' : 'icon-eye'"/>
      </div>

      <span>{{ errors[0] }}</span>
    </div>
  </ValidationProvider>
</template>

<script>
  import { ValidationProvider } from 'vee-validate';
  import MasterDefault from '@/assets/flags/MasterDefault.svg';
  import Master from '@/assets/flags/Master.svg';
  import Elo from '@/assets/flags/Elo.svg';
  import Visa from '@/assets/flags/Visa.svg';
  import Aura from '@/assets/flags/Aura.png';
  import Jcb from '@/assets/flags/Jcb.svg';
  import Amex from '@/assets/flags/Amex.svg';
  import Discover from '@/assets/flags/Discover.svg';
  import Banese from '@/assets/flags/Banese.svg';
  import Hiper from '@/assets/flags/Hiper.svg';

  export default {
    name: 'PasswordInput',

    props: {
      type: {
        type: String,
        default: "text",
        validator(value) {
          return [
            "url",
            "text",
            "password",
            "tel",
            "search",
            "number",
            "email",
          ].includes(value);
        },
      },

      vid: {
        type: String,
        default: undefined
      },

      placeholder: {
        type: String,
        required: true
      },

      errorApi: {
        type: Boolean,
        default: false
      },

      label: {
        type: String,
        default: ''
      },

      styles: {
        type: String,
        default: ''
      },

      rules: {
        type: [Object, String],
        default: ''
      },

      value: {
        type: null,
        required: ''
      },

      maxLength: {
        type: Number,
        required: false
      },

      hasFlag: {
        type: Boolean,
        required: false
      },

      mask: {
        type: String,
        required: false
      },

      refName: {
        type: String,
        required: false
      },

      disable: {
        type: Boolean,
        default: false
      }
    },

    components: {
      ValidationProvider,
    },

    data() {
      return {
        innerValue: null,
        visibility: false,
        hasError: false,
        inputType: 'text',
        name: null,

        path: MasterDefault,
        master: Master,
        visa: Visa,
        elo: Elo,
        aura: Aura,
        jcb: Jcb,
        amex: Amex,
        discover: Discover,
        banese: Banese,
        hiper: Hiper,
        masterDefault: MasterDefault,

        nomask: {
          mask: '*'.repeat(255),
          tokens: {
            '*': { pattern: /./ }
          }
        }
      };
    },

    created() {
      this.inputType = this.type;

      if (this.value) {
        this.innerValue = this.value;
      }
    },

    mounted() {
      if (this.refName) {
        this.name = this.refName;
      } else {
        this.name = this.label.toLowerCase();
      }
    },

    computed: {
      hasValue() {
        return !!this.innerValue;
      },

      flagImage() {
        return 'url(' + `${this.path}` + ') #FFF no-repeat 95%';
      }
    },

    watch: {
      innerValue(value) {
        this.$emit("input", value);

        this.path = this.identifyFlag(this.$service.isValidCard(value));
      },

      value(val) {
        if (val !== this.innerValue) {
          this.innerValue = val;
        }
      }
    },

    methods: {
      isPassword() {
        return this.type == 'password';
      },

      toggleVisibility() {
        if (this.visibility) {
          this.visibility = false;

          this.inputType = 'password';
        } else {
          this.visibility = true;

          this.inputType = 'text';
        }
      },

      identifyFlag(flag) {
        switch (flag) {
          case 'visa':
            return this.visa;
          case 'master':
            return this.master;
          case 'elo':
            return this.elo;
          case 'aura':
            return this.aura;
          case 'jcb':
            return this.jcb;
          case 'amex':
            return this.amex;
          case 'discover':
            return this.discover;
          case 'banese':
            return this.banese;
          case 'hiper':
            return this.hiper;
          default:
            return this.masterDefault;
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  .static-input-container {
    width: 100%;

    &.large {
      max-width: 20rem;
    }

    &.medium {
      max-width: 15rem;
    }

    &.small {
      max-width: 11rem;
    }

    p {
      margin-bottom: 0.5rem;
    }

    span {
      color: #b93a5b;
    }

    .input-container {
      input {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        width: 100%;
        height: 48px;
        border: 1px solid #DADADA;
        background: #FFF;
        border-radius: 6px;
        padding: 1rem;

        &[type="number"]::-webkit-inner-spin-button,
        &[type="number"]::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        &.passwd {
          padding: 0.5rem 3rem 0.5rem 1rem;
        }

        &.error {
          border: 2px solid #b93a5b;
        }
      }

      .icon-container {
        color: #8E8E8E;;
        float: right;
        position: relative;
        bottom: 35px;
        right: 15px;
        height: 0;

        i {
          font-size: 1.5rem;
        }
      }

      span {
        color: #b93a5b;
        font-size: 12px;
        margin-top: 0.2rem;
        display: flex;
        min-width: 190px;
      }
    }
  }

  @media (max-width: 1045px) {
    .static-input-container {
      &.large {
        max-width: unset;
      }

      &.medium {
        max-width: unset;
      }

      &.small {
        max-width: unset;
      }
    }
  }
</style>