import Vue from 'vue';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store.js';
import VueCookie from 'vue-cookie';
import Axios from 'axios';
import vco from "v-click-outside";
import VueTheMask from 'vue-the-mask';
import Ripple from 'vue-ripple-directive';
import { extend, localize } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import resize from 'vue-element-resize-detector';
import Service from '@/services/service';
import ptBR from 'vee-validate/dist/locale/pt_BR.json';
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});

localize('pt_br', ptBR);

Vue.config.productionTip = false;

Vue.use(resize);
Vue.use(VueTheMask);
Vue.use(VueCookie);
Vue.directive('ripple', Ripple);
Vue.use(vco);

Vue.prototype.$http = Axios;
Vue.prototype.$service = Service;

if (process.env.NODE_ENV == 'production') {
  Sentry.init({
    Vue,
    dsn: "https://9a8966d4cc924f1098c85641ce74e375@o1279288.ingest.sentry.io/6685567",
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ["renegociacao.institutomix.com.br", /^\//],
      }),
    ],
    tracesSampleRate: 1.0,
  });
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');