<template>
  <image-view-component>
    <template v-slot:form>
      <ValidationObserver
        tag="div"
        class="form"
        ref="formLogin"
        v-slot="{ passes }"
      >
        <form @submit.prevent="passes(login)">
          <h3>Acessar</h3>

          <error-card
            v-if="hasError"
            title="Ops. Algo de errado aconteceu."
          />

          <input-field
            label="Usuário"
            class="input"
            refName="username"
            placeholder="CPF ou código de acesso"
            :mask="isTaxpayerId ? '###.###.###-##' : null"
            rules="required"
            @keydown.native.space.prevent
            v-model="data.username"
          />

          <input-field
            label="Senha"
            placeholder="Senha"
            rules="required"
            v-model="data.password"
            type="password"
          />

          <div class="options-container">
            <div class="reset-password-container">
              <router-link
                tag="p"
                to="/reset"
              >
                Esqueci minha senha
              </router-link>
            </div>

            <button-component
              text="Acessar"
              styles="primary"
              :hasLoader="hasRequest"
              :isDisabled="hasRequest"
              size="large"
              v-ripple="'rgba(255, 255, 255, 0.35)'"
            />
          </div>
        </form>
      </ValidationObserver>
    </template>
  </image-view-component>
</template>

<script>
  import Button from '@/components/Button';
  import InputField from '@/components/InputField';
  import ErrorCard from '@/components/ErrorCard';
  import ImageView from '@/components/base/ImageView';
  import { ValidationObserver } from 'vee-validate';

  export default {
    name: 'Login',

    data() {
      return {
        data: {
          username: '',
          password: '',
        },

        hasError: false,
        hasRequest: false,
        isTaxpayerId: false
      };
    },

    components: {
      ValidationObserver,
      'image-view-component': ImageView,
      'button-component': Button,
      'input-field': InputField,
      'error-card': ErrorCard
    },

    watch: {
      'data.username'(value) {
        this.isTaxpayerId = value.length >= 11;
      }
    },

    methods: {
      async login() {
        var isAuth = false;
        this.hasError = false;
        this.hasRequest = true;

        let data = {
          username: this.isTaxpayerId ? this.$service.removeCharacters(this.data.username) : this.data.username,
          password: this.data.password,
        };

        await this.$store.dispatch('setLogin', data)
          .then(() => {
            isAuth = true;
          })
          .catch(() => {
            this.$refs.formLogin.setErrors({
              username: 'Usuário ou senha incorretos',
            });
          });

        if (isAuth) {
          await this.$store.dispatch('getUserData')
          .then(() => {
            this.$router.push('/debts');
          })
          .catch(() => {
            this.$store.dispatch('setLogout');
            this.hasError = true;
          });
        }

        this.hasRequest = false;
      }
    }
  };
</script>

<style lang="scss" scoped>
  .form {
    color: #333;
    width: 100%;
    max-width: 25rem;

    form {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      display: flex;
      width: 100%;

      h3 {
        margin-bottom: 1rem;
      }

      .input {
        margin-bottom: 1rem;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .options-container {
      width: 100%;

      .reset-password-container {
        width: 100%;
        margin-bottom: 2rem;
        margin-top: 0.5rem;
        display: flex;

        p {
          margin-left: auto;
          color: #0052CC;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }

  @media (max-width: 1200px) {
    .form {
      width: 60%;
    }
  }

  @media (max-width: 768px) {
    .form {
      width: 100%;
    }
  }

  @media (max-width: 420px) {
    .form {
      .options-container {
        flex-direction: column-reverse;
        margin-top: 2rem;
        display: flex;

        .reset-password-container {
          justify-content: center;
          margin-top: 1.5rem;

          p {
            margin-left: unset;
          }
        }
      }
    }
  }
</style>