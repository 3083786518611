<template>
  <div class="container">
    <div class="data-container">
      <ValidationObserver
        tag="div"
        class="data"
        ref="userData"
      >
        <h1>
          Insira os seus dados
        </h1>

        <!-- <radio
          label="Usar dados de registro"
          :isChecked="getRadioPullUserData"
          v-model="pullUserData"
        /> -->

        <input-field
          label="E-mail"
          class="one-input"
          :disable="disableUserData"
          refName="email"
          placeholder="Ex. exemplo@mail.com"
          rules="required|mail"
          styles="large"
          type="text"
          @input="validateForm"
          v-model="paymentData.email"
        />

        <div class="two-fields">
          <input-field
            label="Nome completo"
            refName="fullName"
            class="field-gap-right"
            placeholder="Ex. Maria Ferreira Dias"
            rules="required|completedName"
            styles="large"
            type="text"
            @input="validateForm"
            v-model="paymentData.fullName"
          />

          <input-field
            label="CPF"
            refName="taxpayerId"
            placeholder="Ex. 088.888.888-88"
            mask="###.###.###-##"
            rules="required|cpf"
            styles="large"
            type="text"
            @input="validateForm"
            v-model="paymentData.taxpayerId"
          />
        </div>

        <div class="address-label">
          Endereço
        </div>

        <input-field
          label="CEP"
          class="one-input"
          :disable="disableUserData"
          refName="postalCode"
          placeholder="Ex. 88888-888"
          mask="#####-###"
          rules="required|postalCode"
          styles="medium"
          type="text"
          @input="validateForm"
          v-model="paymentData.postalCode"
        />

        <div class="two-fields">
          <input-field
            label="Endereço"
            :disable="disableUserData"
            class="field-gap-right"
            refName="address"
            placeholder="Ex. Avenida São João"
            rules="required"
            styles="large"
            type="text"
            @input="validateForm"
            v-model="paymentData.address"
          />

          <input-field
            label="Número"
            :disable="disableUserData"
            refName="number"
            placeholder="1500"
            styles="small"
            rules="required|numeric"
            type="number"
            @input="validateForm"
            v-model="paymentData.number"
          />
        </div>

        <div class="two-fields">
          <input-field
            label="Bairro"
            :disable="disableUserData"
            class="field-gap-right"
            refName="neighborhood"
            placeholder="Ex. Centro"
            rules="required"
            styles="large"
            type="text"
            @input="validateForm"
            v-model="paymentData.neighborhood"
          />

          <input-field
            label="Complemento"
            :disable="disableUserData"
            styles="large"
            placeholder="Ex. Bloco B - Apartamento 500"
            type="text"
            v-model="paymentData.complement"
          />
        </div>

        <div class="two-fields">
          <input-field
            label="Cidade"
            refName="city"
            class="field-gap-right"
            placeholder="Ex. Araranguá"
            rules="required"
            styles="medium"
            type="text"
            @input="validateForm"
            v-model="paymentData.city"
          />

          <input-field
            label="Estado"
            :disable="disableUserData"
            refName="stateCode"
            placeholder="Ex. SC"
            rules="required"
            styles="small"
            :maxLength="2"
            type="text"
            @input="validateForm"
            v-model="paymentData.stateCode"
          />
        </div>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
  import '@/services/rules';
  import { mapState, mapGetters } from 'vuex';
  import { ValidationObserver } from 'vee-validate';
  import InputField from '@/components/InputField';
  // import Radio from '@/components/Radio';

  export default {
    name: 'UserDataCard',

    data() {
      return {
        pullUserData: false
      };
    },

    components: {
      ValidationObserver,
      // 'radio': Radio,
      'input-field': InputField
    },

    computed: {
      ...mapGetters([
        'getCurrentUserData',
        'getRadioPullUserData'
      ]),

      ...mapState({
        paymentData: state => state.data,
        isBoleto: state => state.isBoleto,
        isCreditCard: state => state.isCreditCard,
        disableUserData: state => state.disableUserData,
      })
    },

    created() {
      this.$store.commit('disableRadioPullUserData');
      this.$store.commit('resetPaymentData');

      this.setUserData();
    },

    watch: {
      'isBoleto'() {
        this.$refs.userData.reset();
      },

      'paymentData.number'(value) {
        if (value.length > 0) {
          this.$refs.userData.refs.number.flags.passed = true;
        }
      }
    },

    methods: {
      setUserData() {
        this.paymentData.taxpayerId = this.getCurrentUserData.document;
        this.paymentData.fullName = this.getCurrentUserData.name;
        this.paymentData.email = this.getCurrentUserData.email;
        this.paymentData.neighborhood = this.getCurrentUserData.neighborhood;
        this.paymentData.postalCode = this.getCurrentUserData.postal_code;
        this.paymentData.stateCode = this.getCurrentUserData.state_code;
        this.paymentData.city = this.getCurrentUserData.city_name;
        this.paymentData.address = this.getCurrentUserData.address_line_1;
      },

      validateForm() {
        if (this.passedForm() && this.validateFields())  {
          if (this.isBoleto) {
            this.$store.commit('enableButton');
            this.$store.commit('setThirdBoletoStepStatus', 'check');
          }
        } else {
          this.$store.commit('disableButton');
          this.$store.commit('disableCreditCardForm');
          this.$store.commit('resetCreditCardData');
          this.$store.commit('setThirdBoletoStepStatus', 'wait');
        }
      },

      passedForm() {
        return !(!this.paymentData.taxpayerId || !this.paymentData.fullName || !this.paymentData.email || !this.paymentData.neighborhood
          || !this.paymentData.postalCode || !this.paymentData.stateCode || !this.paymentData.city || !this.paymentData.address || !this.paymentData.number);
      },

      validateFields() {
        return this.$service.isTaxpayerId(this.paymentData.taxpayerId) && this.$service.isEmail(this.paymentData.email) && this.$service.isPostalCode(this.paymentData.postalCode)
          && this.$service.isMinLength(this.paymentData.stateCode, 2) && this.$service.isFullName(this.paymentData.fullName) && !this.$service.hasSpecialCharacters(this.paymentData.stateCode);
      }
    }
  };
</script>

<style lang="scss" scoped>
  .container {
    display: flex;

    .data-container {
      width: 100%;
    }

    .data {
      background: #efefef;
      width: 100%;
      padding: 1.5rem;
      border-radius: 1.5rem;

      h1 {
        font-size: 1.2rem;
        margin-bottom: 1.5rem;
      }

      .address-label {
        margin-bottom: 1.5rem;
        font-size: 12px;
        color: #6D6F6F;
      }

      .static-input-container {
        margin-bottom: 1rem;
      }

      .two-fields {
        display: flex;

        .field-gap-right {
          margin-right: 1rem;
        }
      }
    }
  }

  @media (max-width: 1199px) {
    .container {
      .data {
        h1 {
          font-size: 1.2rem;
        }

        .two-fields {
          margin-bottom: 0;
        }
      }
    }
  }

  @media (max-width: 1045px) {
    .container {
      .data {
        .two-fields {
          flex-direction: column;
        }
      }
    }
  }

  @media (max-width: 678px) {
    .container {
      .data-container {
        .data {
          padding: 2rem 1rem;
        }
      }
    }
  }
</style>