<template>
  <div class="container" :class="{ 'min-height': !checkoutStatus.isPayment }">
    <header-component/>

    <div v-if="hasFetchDataRequest" class="spinner-container">
      <spinner-component  :styles="spinnerStyle"/>
    </div>

    <div v-else>
      <div>
        <button-component
          class="button-container"
          v-ripple="'rgba(255, 139, 0, 0.2)'"
          styles="secondary"
          iconLeft="icon-arrow-left"
          @click.native="backToOptions"
          text="Voltar"
        />
      </div>

      <div class="content">
        <div class="checkout-container">
          <div class="header-container">
            <checkout-header
              :styles="headerData"
            />

            <div class="checkout">
              <summary-card-component
                v-if="deviceType() === 'Mobile'"
                :data="cardData"
                :isRetract="true"
                class="summary-card-component"
              />

              <div v-if="checkoutStatus.isPayment">
                <div>
                  Escolha a melhor a melhor forma de pagamento para você
                </div>

                <div class="form-container">
                  <status-card
                    step="1"
                    :status="firstStepStatus"
                  />

                  <div class="method-payment-container">
                    <payment-type-card
                      class="payment-type-card"
                      :styles="boletoCardStyles"
                      :isSelected="isBoleto"
                      :isDisabled="disableBoletoPayment"
                      @click.native="setPayment('boleto')"
                    />

                    <payment-type-card
                      class="payment-type-card"
                      :styles="creditCardStyles"
                      :isSelected="isCreditCard"
                      @click.native="setPayment('credit')"
                    />

                    <payment-type-card
                      class="payment-type-card"
                      :styles="pixCardStyles"
                      :isSelected="isPix"
                      @click.native="setPayment('pix')"
                    />
                  </div>
                </div>

                <div class="form-container">
                  <status-card
                    step="2"
                    :status="secondStepStatus"
                    :hasLine="hasPaymentSelected() && !isPix"
                  />

                  <pix-payment-card
                    v-if="isPix"
                    :data="cardData"
                  />

                  <to-pay-card
                    v-else
                    class="form"
                    :class="{ 'disable': disableToPayCard }"
                    :data="cardData"
                  />
                </div>

                <div v-if="isBoleto" class="form-container">
                  <status-card
                    step="3"
                    :status="thirdBoletoStepStatus"
                    :hasLine="false"
                  />

                  <user-data-card
                    class="form"
                    :class="{ 'disable': disableUserData }"
                  />
                </div>

                <div v-else-if="isCreditCard" class="form-container">
                  <status-card
                    step="3"
                    :status="thirdCreditCardStepStatus"
                    :hasLine="false"
                  />

                  <credit-card-method
                    class="form"
                    :class="{ 'disable': disableCreditCardMethod }"
                  />
                </div>

                <div v-if="hasPaymentSelected() && !isPix" class="button-container">
                  <button-component
                    v-ripple="'rgba(255, 255, 255, 0.35)'"
                    class="button"
                    text="Pagar"
                    :isDisabled="disableButton"
                    :hasLoader="hasRequest"
                    @click.native="submitForm"
                  />
                </div>
              </div>

              <div v-else>
                <div v-if="checkoutStatus.isSuccessPayment">
                  <boleto-card-component
                    v-if="checkoutStatus.isBoletoPayment"
                    :data="successBoletoCardComponentData"
                  />

                  <card-component
                    v-else-if="checkoutStatus.isPixPaymentError"
                    :styles="errorPixPaymentCardData"
                  />

                  <card-component
                    v-else
                    :styles="getSuccessPaymentCardData"
                  />
                </div>

                <card-component
                  v-else
                  :styles="errorCardComponentData"
                />
              </div>
            </div>
          </div>

          <summary-card-component
            v-if="deviceType() === 'Desktop'"
            :data="cardData"
            class="summary-card-component"
          />
        </div>
      </div>
    </div>

    <footer-component/>
  </div>
</template>

<script>
  import Header from '@/components/base/Header';
  import Footer from '@/components/base/Footer';
  import SummaryCard from '@/components/SummaryCard';
  import PaymentTypeCard from '@/components/PaymentTypeCard';
  import ToPayCard from '@/components/ToPayCard';
  import Spinner from '@/components/Spinner';
  import UserDataCard from '@/components/UserDataCard';
  import Status from '@/components/Status';
  import Card from '@/components/Card';
  import BoletoCard from '@/components/BoletoCard';
  import Button from '@/components/Button';
  import CreditCardMethod from '@/components/CreditCardMethod';
  import CheckoutHeader from '@/components/CheckoutHeader';
  import PixPaymentCard from '@/components/PixPaymentCard';
  import { mapState, mapGetters } from 'vuex';

  export default {
    name: 'Checkout',

    components: {
      'header-component': Header,
      'footer-component': Footer,
      'credit-card-method': CreditCardMethod,
      'payment-type-card': PaymentTypeCard,
      'summary-card-component': SummaryCard,
      'user-data-card': UserDataCard,
      'button-component': Button,
      'status-card': Status,
      'checkout-header': CheckoutHeader,
      'card-component': Card,
      'boleto-card-component': BoletoCard,
      'to-pay-card': ToPayCard,
      'pix-payment-card': PixPaymentCard,
      'spinner-component': Spinner
    },

    data() {
      return {
        width: null,
        hasRequest: false,
        hasFetchDataRequest: true,
        disableBoletoPayment: false,

        spinnerStyle: {
          color: '#FF9C00',
          borderSize: '1em',
          size: '0.5rem'
        },

        headerData: {
          contractId: '',
          description: {
            text: '',
            color: '#aa1428'
          }
        },

        boletoCardStyles: {
          icon: 'icon-ticket',
          text: 'Boleto'
        },

        cardData: {
          formattedDiscountAmount: '',
          description: '',
          discountAmount: 0
        },

        creditCardStyles: {
          icon: 'icon-credit-card',
          text: 'Cartão'
        },

        pixCardStyles: {
          icon: 'icon-pix-outline',
          text: 'Pix'
        },

        errorPixPaymentCardData: {
          icon: 'icon-error-circle-outline',
          backgroundIcon: '#b81d5b',
          title: 'Não conseguimos gerar um pagamento via Pix no momento',
          subTitle: 'Por favor, espere alguns minutos e em seguida',
          link: '/checkout'
        },

        successBoletoCardComponentData: {
          boletoUrl: ''
        },

        errorCardComponentData: {}
      };
    },

    computed: {
      ...mapGetters([
        'getRepayBoletoToCreditCard',
        'getCurrentDebtId',
        'getCurrentUserData',
        'getSuccessPaymentCardData',
        'getAllDebts'
      ]),

      ...mapState({
        disableToPayCard: state => state.disableToPayCard,
        disableUserData: state => state.disableUserData,
        disableCreditCardMethod: state => state.disableCreditCardMethod,
        isPix: state => state.isPix,
        isBoleto: state => state.isBoleto,
        isCreditCard: state => state.isCreditCard,
        disableButton: state => state.disableButton,
        firstStepStatus: state => state.firstStepStatus,
        secondStepStatus: state => state.secondStepStatus,
        thirdBoletoStepStatus: state => state.thirdBoletoStepStatus,
        thirdCreditCardStepStatus: state => state.thirdCreditCardStepStatus,
        paymentData: state => state.data,
        checkoutStatus: state => state.checkoutStatus,
        successPaymentCardData: state => state.successPaymentCardData
      })
    },

    created() {
      window.onresize = () => {
        this.width = window.innerWidth;
      };

      this.getAllDebts.forEach(item => {
        if (item.debtId === this.getCurrentDebtId) {
          this.headerData.contractId = item.contractId;
          this.headerData.description.text = this.getCurrentUserData.name;
          this.cardData.formattedDiscountAmount = item.formattedDiscountAmount;
          this.cardData.discountAmount = item.discountAmount;
          this.cardData.description = item.formattedDescription;
        }
      });
    },

    mounted() {
      window.scrollTo({ top: 0, behavior: 'smooth' });

      this.width = window.innerWidth;
      this.$store.commit('resetState');
      this.$store.commit('resetPaymentData');
      this.$store.commit('resetCreditCardData');
      this.$store.commit('disableRepayBoletoToCreditCard');

      this.fetchData();
    },

    watch: {
      getRepayBoletoToCreditCard() {
        if (this.getRepayBoletoToCreditCard) {
          this.checkoutStatus.isPayment = true;
          this.setCreditCardPayment();
          this.disableBoletoPayment = true;
        }
      },

      width() {
        this.deviceType();
      }
    },

    methods: {
      async fetchData() {
        this.hasFetchDataRequest = true;

        await this.$store.dispatch('getDebtId', this.getCurrentDebtId)
          .then(res => {
            let resData = res.data;
            let formattedResData = this.$service.formatDebt(resData);

            if(res.data.transaction != undefined && this.$service.toUpperCase(formattedResData.paymentStatus) == 'PAID') {
              this.checkoutStatus.isPayment = false;
              this.checkoutStatus.isSuccessPayment = true;
            }

            if (formattedResData.linkBoleto != null) {
              this.checkoutStatus.isPayment = false;
              this.checkoutStatus.isBoletoPayment = true;
              this.successBoletoCardComponentData.boletoUrl = formattedResData.linkBoleto;
            }
          });

        this.hasFetchDataRequest = false;
      },

      deviceType() {
        return this.width > 1020 ? 'Desktop' : 'Mobile';
      },

      setPayment(type) {
        this.$store.commit('resetState');
        this.$store.commit('resetPaymentData');

        switch(this.$service.toUpperCase(type)) {
          case 'PIX':
            this.setPixPayment();
            break;
          case 'BOLETO':
            this.setBoletoPayment();
            break;
          default:
            this.setCreditCardPayment();
            break;
        }
      },

      setBoletoPayment() {
        if (!this.disableBoletoPayment) {
          this.$store.commit('setBoleto');
          this.$store.commit('setFirstStepStatus', 'check');
          this.$store.commit('setSecondStepStatus', 'check');
          this.$store.commit('setThirdBoletoStepStatus', 'wait');
        }
      },

      setCreditCardPayment() {
        this.$store.commit('setCreditCard');
        this.$store.commit('setFirstStepStatus', 'check');
        this.$store.commit('setSecondStepStatus', 'wait');
        this.$store.commit('setThirdCreditCardStepStatus', 'default');
      },

      setPixPayment() {
        this.$store.commit('setPix');
        this.$store.commit('setFirstStepStatus', 'check');
        this.$store.commit('setSecondStepStatus', 'wait');
      },

      submitForm() {
        if (this.isBoleto) {
          let paymentData = {
            payment: {
              method: 'Boleto',
            },

            customer: {
              first_name: this.$service.firstWord(this.paymentData.fullName),
              last_name: this.$service.lastWord(this.paymentData.fullName),
              taxpayer_id: this.$service.removeCharacters(this.paymentData.taxpayerId),
              address: {
                line1: this.paymentData.address,
                line2: this.paymentData.number,
                neighborhood: this.paymentData.neighborhood,
                city: this.paymentData.city,
                state_code: this.paymentData.stateCode,
                country_code: 'BR',
                postal_code: this.$service.removeCharacters(this.paymentData.postalCode)
              }
            }
          };

          this.makePayment(paymentData, 'Boleto');
        } else {
          let expirationMonth = this.paymentData.dateValidate.substr(0, 2);
          let expirationYear = '20' + this.paymentData.dateValidate.substr(3, 5);

          let paymentData = {
            payment: {
              method: 'CreditCard',
              credit_card: {
                number: this.paymentData.cardNumber,
                holder_name: this.paymentData.fullName,
                expiration_month: expirationMonth,
                expiration_year: expirationYear,
                security_code: this.paymentData.securityCode,
                installments: {
                  count: this.paymentData.installments.numberOfInstallments
                }
              }
            }
          };

          this.makePayment(paymentData, 'CreditCard');
        }
      },

      makePayment(payment, paymentType) {
        this.hasRequest = true;
        this.$store.commit('disableButton');
        this.$store.commit('disableCreditCardForm');
        this.$store.commit('disableUserDataForm');
        this.$store.commit('disableToPayCard');

        let data = JSON.stringify(payment);

        this.$store.dispatch('setPayment', data)
          .then(res => {
            let responseData = res.data;

            if(responseData.payment.method == 'CreditCard') {
              this.checkoutStatus.isPayment = false;
              this.checkoutStatus.isSuccessPayment = true;
              this.checkoutStatus.isBoletoPayment = false;
              this.successPaymentCardData.transaction.id = responseData.transaction_id;
              this.successPaymentCardData.transaction.date = this.formattedCurrentDate();
            }

            if(responseData.payment.method == 'Boleto') {
              this.checkoutStatus.isPayment = false;
              this.checkoutStatus.isSuccessPayment = true;
              this.checkoutStatus.isBoletoPayment = true;
              this.successBoletoCardComponentData.boletoUrl = responseData.payment.boleto.url;
            }
          })
          .catch(() => {
            this.checkoutStatus.isPayment = false;
            this.checkoutStatus.isSuccessPayment = false;

            this.errorCardComponentData.link = '/checkout';

            if (paymentType === 'CreditCard') {
              this.errorCardComponentData = {
                icon: 'icon-error-circle-outline',
                backgroundIcon: '#b81d5b',
                title: 'Não conseguimos efetuar seu pagamento no momento',
                subTitle: 'Por favor, entre em contato o com seu banco para melhor entendimento e em seguida',
                items: {
                  title: 'Os erros mais comuns apresentados são:',
                  itemsArray: [
                    'Cartão bloqueado',
                    'Compra não autorizada pelo banco',
                    'Saldo/limite indisponível'
                  ]
                },
                link: '/checkout',
                footerText: 'Caso o erro persistir, favor entrar em contato com sua unidade Instituto Mix'
              };
            } else {
              this.errorCardComponentData = {
                icon: 'icon-error-circle-outline',
                backgroundIcon: '#b81d5b',
                title: 'Não conseguimos gerar o seu boleto no momento',
                subTitle: 'Por favor, revise seus dados e ',
                afterLink: ', caso o erro persistir, tente novamente mais tarde',
                link: '/checkout'
              };
            }
          })
          .finally(() => {
            this.hasRequest = false;
            window.scrollTo({ top: 0, behavior: 'smooth' });
          });
      },

      hasPaymentSelected() {
        return this.isPix || this.isBoleto || this.isCreditCard;
      },

      backToOptions() {
        this.$router.push('/debts').catch(() => {});
      },

      formattedCurrentDate() {
        let currentDate = new Date();

        return this.$service.dateFormat(currentDate, 'DD/MM/YYYY HH:mm:ss');
      }
    }
  };
</script>

<style lang="scss" scoped>
  .container {
    display: flex;
    flex-direction: column;
    height: 100%;

    &.min-height {
      min-height: 100vh;
    }

    .spinner-container {
      height: 100%;
      justify-content: center;
      align-items: center;
      display: flex;
    }

    .error-page {
      padding: 2rem;
      display: flex;
      justify-content: center;

      .card-error {
        margin-top: 1.5rem;
        width: 50rem;
      }
    }

    .button-container {
      margin-left: auto;
      margin-right: 3rem;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }

    .content {
      display: flex;
      justify-content: center;

      .checkout-container {
        padding: 0 1.5rem;
        margin-bottom: 4rem;
        display: flex;
        justify-content: center;
        width: 1440px;

        .summary-card-component {
          min-width: 450px;
        }

        .header-container {
          margin-right: 2rem;
          width: 100%;

          .checkout {
            .method-payment-container {
              display: flex;
              padding: 0.5rem;
              overflow: auto;

              .payment-type-card {
                margin-right: 2rem;

                &:last-child {
                  margin-right: 0;
                }
              }
            }

            .form-container {
              display: flex;
              margin-top: 2rem;

              .form {
                width: 100%;
              }
            }

            .disable {
              pointer-events: none;
              user-select: none;
              opacity: 0.4;
              filter: blur(1.5px);
            }
          }
        }

        .button-container {
          margin-top: 1.5rem;
          width: 100%;
          display: flex;
          justify-content: flex-end;

          .button {
            width: 12.5rem;
            height: 48px;
            border-radius: 4rem;
          }
        }
      }
    }

    .error-card {
      padding: 2rem;
      display: flex;
      justify-content: center;

      .card {
        width: 60rem;
      }
    }
  }

  @media (max-width: 1305px) {
    .container {
      .button-container {
        margin-left: 0.5rem;
      }

      .checkout-container {
        font-size: 1rem;
        padding: 0 1rem;
        margin: 0;

        .button-container {
          margin-bottom: 2rem;
        }
      }
    }
  }

  @media (max-width: 1020px) {
    .container {
      .content {
        .checkout-container {
          .summary-card-component {
            min-width: unset;
          }

          .header-container {
            margin-right: 0;
          }
        }
      }
    }
  }

  @media (max-width: 905px) {
    .container {
      .content {
        .checkout-container {
          .button-container {
            justify-content: center;
          }
        }
      }
    }
  }

  @media (max-width: 580px) {
    .container {
      .content {
        display: block;

        .checkout-container {
          width: unset;

          .header-container {
            .checkout {
              .method-payment-container {
                .payment-type-card {
                  margin-right: 1rem;
                }
              }
            }
          }
        }
      }
    }
  }
</style>