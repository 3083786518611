<template>
  <ValidationObserver
    tag="div"
    class="form"
    ref="resetPasswordForm"
    v-slot="{ passes }"
  >
    <form @submit.prevent="passes(resetPassword)">
      <h3>Esqueci minha senha</h3>

      <div v-if="!!getPasswordEmail" class="email-container">
        <p>E-mail</p>

        <p>
          <b>{{ getPasswordEmail }}</b>
        </p>
      </div>

      <input-field
        label="Código"
        class="input"
        refName="code"
        placeholder="Digite o código"
        rules="required"
        @keydown.native.space.prevent
        v-model="code"
      />

      <input-field
        vid="pass"
        label="Nova senha"
        class="input"
        placeholder="Digite a nova senha"
        rules="required|min:7"
        @keydown.native.space.prevent
        v-model="newPassword"
        type="password"
      />

      <input-field
        label="Confirmar senha"
        class="input"
        placeholder="Digite a mesma senha"
        rules="required|confirmed:pass"
        @keydown.native.space.prevent
        type="password"
      />

      <button-component
        text="Enviar código"
        styles="primary"
        :hasLoader="hasRequest"
        :isDisabled="hasRequest"
        size="large"
        v-ripple="'rgba(255, 255, 255, 0.35)'"
      />

      <div class="back-button-container">
        <p @click="resendCode">
          Reenviar código
        </p>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
  import Button from '@/components/Button';
  import InputField from '@/components/InputField';
  import { ValidationObserver } from 'vee-validate';
  import { mapGetters } from 'vuex';

  export default {
    name: 'ResetPasswordForm',

    data() {
      return {
        newPassword: '',
        code: '',
        hasRequest: false,
      };
    },

    computed: {
      ...mapGetters([
        'getPasswordEmail'
      ])
    },

    components: {
      ValidationObserver,
      'button-component': Button,
      'input-field': InputField,
    },

    methods: {
      resetPassword() {
        this.hasRequest = true;

        let data = {
          code: this.code,
          username: this.username,
          newPassword: this.newPassword
        };

        this.$store.dispatch('changePassword', data)
          .then(() => {
            this.$store.commit('setResetPasswordStatus', false);
            this.$store.commit('resetForgotPassoword');
          })
          .catch(() => {
            this.$refs.resetPasswordForm.setErrors({
              code: 'Código inválido'
            });
          })
          .finally(() => {
            this.hasRequest = false;
          });
      },

      resendCode() {
        this.$store.commit('resetForgotPassoword');
      }
    }
  };
</script>

<style lang="scss" scoped>
  .form {
    color: #333;
    width: 100%;
    max-width: 25rem;

    form {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      display: flex;
      width: 100%;

      h3 {
        margin-bottom: 1rem;
      }

      .email-container {
        margin-bottom: 1.5rem;
        margin-top: 1rem;
        width: 100%;

        p {
          text-align: unset;
          margin-bottom: unset;
        }
      }

      p {
        text-align: center;
        margin-bottom: 2rem;
      }

      .input {
        margin-bottom: 1rem;
      }

      .back-button-container {
        margin-top: 2rem;
        display: flex;

        p {
          margin-left: auto;
          color: #0052CC;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }

  @media (max-width: 1200px) {
    .form {
      width: 60%;
    }
  }

  @media (max-width: 768px) {
    .form {
      width: 100%;
    }
  }
</style>