<template>
  <div class="container">
    <div class="data-container">
      <ValidationObserver
        tag="div"
        class="data"
        ref="creditCard"
      >
        <h1>Meio de pagamento - cartão de crédito</h1>

        <radio
          label="Usar dados de registro"
          :isChecked="getRadioPullUserData"
          v-model="pullUserData"
        />

        <div class="tree-fields">
          <input-field
            class="field-gap-right"
            :disable="disableCreditCardMethod"
            refName="numberCreditCard"
            label="Número do cartão"
            placeholder="Ex. 5381579886310193"
            mask="################"
            :maxLength="16"
            rules="required|card"
            styles="large"
            type="text"
            :hasFlag="true"
            v-model="paymentData.cardNumber"
            @input="validateForm"
          />

          <div class="two-fields">
            <input-field
              class="field-gap-right"
              :disable="disableCreditCardMethod"
              refName="dateValidateCard"
              label="Data de validade"
              placeholder="Ex. 05/20"
              mask="##/##"
              rules="required|validExpirationCard"
              styles="small"
              type="text"
              v-model="paymentData.dateValidate"
              @input="validateForm"
            />

            <input-field
              class="field-gap"
              :disable="disableCreditCardMethod"
              refName="codeSecurity"
              label="Código de segurança"
              placeholder="Ex. 456"
              mask="####"
              rules="required"
              styles="small"
              type="text"
              v-model="paymentData.securityCode"
              @input="validateForm"
            />
          </div>
        </div>

        <div class="tree-fields">
          <input-field
            class="field-gap-right"
            :disable="disableCreditCardMethod"
            refName="completedName"
            label="Nome comp. do titular"
            placeholder="Ex. Maria Ferreira Dias"
            rules="required|completedName"
            styles="large"
            type="text"
            v-model="paymentData.fullName"
            @input="validateForm"
          />

          <input-field
            class="field-gap-right"
            :disable="disableCreditCardMethod"
            refName="cpf"
            label="CPF"
            placeholder="Ex. 088.888.888-88"
            mask="###.###.###-##"
            rules="required|cpf"
            styles="medium"
            type="text"
            v-model="paymentData.taxpayerId"
            @input="validateForm"
          />

          <input-field
            class="field-gap-right"
            :disable="disableCreditCardMethod"
            refName="dateOfBirth"
            label="Data de nascimento"
            placeholder="Ex. 20/05/1987"
            rules="required"
            mask="##/##/####"
            styles="small"
            type="text"
            v-model="paymentData.dateOfBirth"
            @input="validateForm"
          />
        </div>

        <input-field
          :disable="disableCreditCardMethod"
          refName="phoneNumber"
          label="Nº Celular"
          placeholder="Ex. (48) 99999-9999"
          mask="phone"
          rules="required|phone"
          styles="medium"
          type="text"
          v-model="paymentData.phone"
          @input="validateForm"
        />
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
  import '@/services/rules';
  import { mapState, mapGetters } from 'vuex';
  import InputField from '@/components/InputField';
  import { ValidationObserver } from 'vee-validate';
  import Radio from '@/components/Radio';

  export default {
    name: 'CreditCardMethod',

    data() {
      return {
        pullUserData: false
      };
    },

    components: {
      ValidationObserver,
      'radio': Radio,
      'input-field': InputField,
    },

    computed: {
      ...mapGetters([
        'getCurrentUserData',
        'getRadioPullUserData'
      ]),

      ...mapState({
        paymentData: state => state.data,
        disableCreditCardMethod: state => state.disableCreditCardMethod,
      })
    },

    created() {
      this.$store.commit('disableRadioPullUserData');
      this.$store.commit('resetPaymentData');
    },

    watch: {
      'disableCreditCardMethod'() {
        this.$refs.creditCard.reset();
      },

      'pullUserData'() {
        this.$store.commit('checkedRadioPullUserData');

        this.setUserData();
      }
    },

    methods: {
      setUserData() {
        this.paymentData.taxpayerId = this.getCurrentUserData.document;
        this.paymentData.fullName = this.getCurrentUserData.name;
        this.paymentData.phone = this.getCurrentUserData.phone_number;
      },

      validateForm() {
        if (this.passedForm() && this.validateFields()) {
          this.$store.commit('enableButton');
          this.$store.commit('setThirdCreditCardStepStatus', 'check');
        } else {
          this.$store.commit('disableButton');
          this.$store.commit('setThirdCreditCardStepStatus', 'wait');
        }
      },

      passedForm() {
        return !(!this.paymentData.cardNumber || !this.paymentData.dateValidate || !this.paymentData.cardNumber || !this.paymentData.securityCode
          || !this.paymentData.fullName || !this.paymentData.taxpayerId || !this.paymentData.dateOfBirth || !this.paymentData.phone);
      },

      validateFields() {
        return this.$service.isTaxpayerId(this.paymentData.taxpayerId) && this.$service.isFullName(this.paymentData.fullName) && this.$service.isValidCard(this.paymentData.cardNumber)
          && this.$service.isDate(this.paymentData.dateValidate) && this.$service.isDateOfBirth(this.paymentData.dateOfBirth) && this.$service.isValidPhone(this.paymentData.phone);
      }
    }
  };
</script>

<style lang="scss" scoped>
  .container {
    display: flex;

    .data-container {
      width: 100%;
    }

    .data {
      background: #efefef;
      width: 100%;
      padding: 1.5rem;
      border-radius: 1.5rem;

      h1 {
        font-size: 1.2rem;
        margin-bottom: 1.5rem;
      }

      .static-input-container {
        margin-bottom: 1rem;
      }

      .tree-fields {
        display: flex;

        .field-gap-right {
          margin-right: 1rem;
          display: flex;
          flex-direction: column;

          &:last-child {
            margin-right: 0;
          }
        }

        .two-fields {
          display: flex;

          .static-input-container {
            display: flex;
            flex-direction: column;

            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }
  }

  @media (max-width: 1199px) {
    .container {
      .data {
        h1 {
          font-size: 1.2rem;
        }
      }
    }
  }

  @media (max-width: 1245px) {
    .container {
      .data {
        .tree-fields {
          flex-direction: column;

          .field-gap-right {
            max-width: unset;
          }

          .field-gap {
            max-width: unset;
          }
        }
      }
    }
  }

  @media (max-width: 1120px) {
    .container {
      .data {
        .tree-fields {
          .two-fields {
            flex-direction: column;
          }
        }
      }
    }
  }


  @media (max-width: 678px) {
    .container {
      .data-container {
        .data {
          padding: 2rem 1rem;
        }
      }
    }
  }
</style>
