<template>
  <footer :class="{ 'position': hasSpinner }">
    © Copyright {{ currentFullYear }} Instituto Mix de Profissões - Todos os direitos reservados.

    <img src="@/assets/images/poweredby-white.png">
  </footer>
</template>

<script>
  import { mapState } from 'vuex';

  export default ({
    data() {
      return {
        currentFullYear: '',
      };
    },

    computed: {
      ...mapState({
        hasSpinner: state => state.hasSpinner,
      })
    },

    mounted() {
      let date = new Date();
      this.currentFullYear = date.getFullYear();
    }
  });
</script>

<style lang="scss" scoped>
  footer {
    background: #AA1428;
    min-width: 320px;
    width: 100%;
    color: #FFF;
    font-size: 14px;
    padding: 0.5rem 1.5rem;
    margin-top: auto;
    z-index: 1;
    display: flex;
    align-items: center;

    img {
      width: 105px;
      margin-left: auto;
    }
  }

  footer .position {
    position: absolute;
    bottom: 0;
  }

  @media (max-width: 820px) {
    footer {
      font-size: 12px;
      flex-direction: column-reverse;
      text-align: center;

      img {
        margin-left: unset;
        margin-bottom: 0.5rem;
      }
    }
  }
</style>