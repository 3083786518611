<template>
  <div class="loader" :style="convertedStyles"></div>
</template>

<script>
  export default {
    name: 'Spinner',

    data() {
      return {
        convertedStyles: {}
      };
    },

    props: {
      styles: {
        type: Object,
        required: true,
      }
    },

    mounted() {
      this.convertedStyles = {
        'font-size': this.styles.size,
        'border-left': this.styles.borderSize + ' solid' + this.styles.color,
        'border-top': this.styles.borderSize + ' solid rgba(255, 255, 255, 0.2)',
        'border-right': this.styles.borderSize + ' solid rgba(255, 255, 255, 0.2)',
        'border-bottom': this.styles.borderSize + ' solid rgba(255, 255, 255, 0.2)',
      };
    }
  };
</script>

<style lang="scss" scoped>
  .loader {
    overflow: hidden;
    position: relative;
    text-indent: -9999em;
    transform: translateZ(0);
    animation: spinner 1.1s infinite linear;

      &, &:after {
      border-radius: 50%;
      width: 10em;
      height: 10em;
    }
  }

  @-webkit-keyframes spinner {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @keyframes spinner {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
</style>