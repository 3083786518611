import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '@/views/Login.vue';
import Reset from '@/views/Reset.vue';
import Checkout from '@/views/Checkout.vue';
import RenegotiationOptions from '@/views/RenegotiationOptions.vue';
import NotFound from '@/views/NotFound';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'login',
    component: Login,
    meta: {
      title: 'Entrar',
      requiresAuth: false
    }
  },

  {
    path: '/reset',
    name: 'reset',
    component: Reset,
    meta: {
      title: 'Esqueci minha senha',
      requiresAuth: false
    }
  },

  {
    path: '/checkout',
    name: 'checkout',
    component: Checkout,
    meta: {
      title: 'Pagamento',
      requiresAuth: true
    }
  },

  {
    path: '/debts',
    name: 'debts',
    component: RenegotiationOptions,
    meta: {
      title: 'Propostas',
      requiresAuth: true
    }
  },

  {
    path: '*',
    name: 'notfound',
    component: NotFound,
    meta: {
      title: 'Página não encontrada'
    }
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  const hasToken = !!Vue.cookie.get('token');

  if (to.meta && to.meta.title) {
    document.title = to.meta.title + ' | IM Renegociação';
  }

  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (!hasToken) {
      next('/');
    }

    next();
  } else {
    if (hasToken) {
      next('/debts');
    }

    next();
  }
});

export default router;