import { extend } from 'vee-validate';
import { cpf } from 'cpf-cnpj-validator';
import moment from 'moment';
import { required, email, confirmed, min } from 'vee-validate/dist/rules';

extend('required', {
  ...required,
  message: 'Campo obrigatório'
});

extend('confirmed', {
  ...confirmed,
  message: 'Senhas não conferem'
});

extend('min', {
  ...min,
  message: 'Campo deve ter no mínimo {length} caracteres'
});

extend('email', {
  ...email,
  message: 'E-mail inválido'
});

extend('mail', {
  message: 'E-mail inválido',
  validate: value => {
    if (value != null) {
      let regEmail = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;

      return !!value.match(regEmail);
    }
  }
});

extend('cpf', {
  message: 'CPF inválido',
  validate: value => {
    if (value != null) {
      let strCpf  = value.replace(/\.|-/g,"");

      return cpf.isValid(strCpf);
    }
  }
});

extend('completedName', {
  message: 'Digite o nome completo',
  validate: value => {
    if (value != null) {
      if (value.indexOf(' ') >= 0) {
        let regName = /^[\p{L}'][ \p{L}'-]*[\p{L}]$/u;

        return value.match(regName);
      }
    }
  }
});

extend('validExpirationCard', {
  message: 'Data inválida',
  validate: value => {
    if (value != null && value.length == 5) {
      let currentMonth = new Date().getMonth() + 1;
      let currentYear = new Date().getFullYear().toString().substr(-2);

      let validateMonth = value.toString().substr(0, 2);
      let validateYear = value.toString().substr(-2);

      if ((validateMonth >= currentMonth && validateYear >= currentYear)
         || (validateYear > currentYear)) {
        return moment(value, "MM-YY").isValid();
      }
    }
  }
});

extend('date', {
  message: 'Data de nascimento inválida',
  validate: value => {
    let valueChar = value.replace(/[^0-9]/g, '');

    if (value != null && valueChar.length === 8) {
      return moment(value, "DD-MM-YYYY").isValid();
    }
  }
});

extend('postalCode', {
  message: 'CEP inválido',
  validate: value => {
    if (value != null) {
      let valueChar = value.replace(/[^0-9]/g, '');

      return valueChar.length === 8;
    }
  }
});

/* Caution: Changing a regex, you must change it everywhere */
extend('card', {
  message: 'Cartão inválido e/ou não suportado',
  validate: value => {
    if (value != null) {
      let regCards = {
        visa: /^4[0-9]{12}(?:[0-9]{3})/,
        master: /^5[1-5]|^2(2(2[1-9]|[3-9])|[3-6]|7([01]|20))/,
        elo: /^4011(78|79)|^43(1274|8935)|^45(1416|7393|763(1|2))|^50(4175|6699|67[0-6][0-9]|677[0-8]|9[0-8][0-9]{2}|99[0-8][0-9]|999[0-9])|^627780|^63(6297|6368|6369)|^65(0(0(3([1-3]|[5-9])|4([0-9])|5[0-1])|4(0[5-9]|[1-3][0-9]|8[5-9]|9[0-9])|5([0-2][0-9]|3[0-8]|4[1-9]|[5-8][0-9]|9[0-8])|7(0[0-9]|1[0-8]|2[0-7])|9(0[1-9]|[1-6][0-9]|7[0-8]))|16(5[2-9]|[6-7][0-9])|50(0[0-9]|1[0-9]|2[1-9]|[3-4][0-9]|5[0-8]))/,
        hiper: '^606282|^637095|^637599|^637568',
        discover: /^6(?:011|5[0-9]{2})[0-9]{12}/,
        jcb: /^(?:2131|1800|35\d{3})\d{11}/,
        banese: /^636117/,
        amex: /^3[47][0-9]{13}/,
        aura: /^((?!504175))^((?!5067))(^50[0-9])/
      };

      for (var card in regCards) if (value.match(regCards[card])) return true;
      return false;
    }
  }
});

extend('phone', {
  message: 'Número de celular',
  validate: value => {
    if (value.length >= 18 && value.length <= 19) {
      let regName = /^(?:\+)[0-9]{2}\s?(?:\()[0-9]{2}(?:\))\s?[0-9]{4,5}(?:-)[0-9]{4}$/;

      return value.match(regName);
    }
  }
});
