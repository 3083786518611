<template>
  <div class="image-view-container">
    <div class="image-container">
      <img :src="image">
    </div>

    <div class="form-container">
      <div class="logo-container">
        <img src="@/assets/images/logo-red.png">
      </div>

      <slot name="form" />

      <slot name="options" />
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ImageView',

    props: {
      image: {
        type: String,
        default: require('@/assets/images/login-image.png')
      }
    }
  };
</script>

<style lang="scss" scoped>
  .image-view-container {
    grid-template-columns: 65% 35%;
    display: grid;
    height: 100vh;

    .image-container {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      overflow: hidden;
      background: linear-gradient(54.06deg, #BA1717 -0.77%, #E10913 100%);
      background-repeat: no-repeat;
      background-position: center center;

      img {
        width: 90%;
        max-width: 990px;
      }
    }

    .form-container {
      background: #FFF;
      padding: 1.5rem;

      flex-direction: column;
      justify-content: center;
      align-items: center;
      display: flex;

      .logo-container {
        margin-bottom: 2rem;

        img {
          width: 245px;
          height: 60px;
        }
      }
    }
  }

  @media (max-width: 1200px) {
    .image-view-container {
      grid-template-columns: 1fr 100%;

      .form-container {
        border-top: 10px solid #AA1428;
      }
    }
  }
</style>