<template>
	<div class="error-card" id="card-error">
    <div class="header">
      <i class="icon-error"/>

      <div class="title">
        {{ title }}
      </div>

      <i class="icon-close" @click="toggleCard"/>
    </div>

    <div v-if="errors" class="errors">
      <p class="error" v-for="error of errors" v-bind:key="error.id">
        - {{ error }}
      </p>
    </div>
	</div>
</template>

<script>
  export default {
    name: 'ErrorCard',

    props: {
      title: {
        type: String,
        required: true
      },

      errors: {
        type: Array,
        required: false
      }
    },

    methods: {
      toggleCard() {
        this.$root.$emit('hideErrorCard', false);
      }
    }
  };
</script>

<style lang="scss" scoped>
	.error-card {
    width: 100%;
    background: #FF5A5A10;
    border-radius: 10px;
    border: 1px solid #FF5A5A;
    border-left-width: 10px;
    margin-bottom: 1rem;
    padding: 1rem;

    .header {
      display: flex;

      .title {
        padding: 0 1rem;
        font-size: 14px;
        font-weight: 400;
      }

      i {
        display: flex;
        align-items: center;

        &.icon-error {
          color: #FF5A5A;
          font-size: 20px;
        }

        &.icon-close {
          margin-left: auto;
          color: #979797;
          font-size: 12px;
          cursor: pointer;
        }
      }
    }

    .errors {
      margin-left: 3rem;
      margin-top: 1rem;
      margin-bottom: -0.5rem;

      .error {
        margin-bottom: 0.5rem;
        font-weight: 300;
        font-size: 14px;
      }
    }
	}
</style>