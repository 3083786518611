<template>
	<div class="payment-card-container" :class="{ 'disabled': isDisabled }">
    <div class="icon-container" :class="{ 'selected': isSelected }">
      <div class="icon">
        <i :class="styles.icon"/>
      </div>
    </div>

    <div class="type">
      {{ styles.text }}
    </div>
	</div>
</template>

<script>
  export default {
    name: 'PaymentCard',

    props: {
      isSelected: {
        type: Boolean,
        default: false
      },

      isDisabled: {
        type: Boolean,
        default: false
      },

      styles: {
        type: Object,
        default() {
          return {
            icon: 'icon-credit-card',
            text: 'Text'
          };
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  .payment-card-container {
    min-width: 110px;
    width: 130px;
    border: 1px solid #DADADA;
    border-radius: 6px;
    cursor: pointer;

    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    .icon-container {
      background: #EFEFEF;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 6px 6px 0 0;
      padding: 0.5rem;

      .icon {
        width: 50px;
        height: 50px;
        border-radius: 10rem;
        background: #FFF;
        display: flex;
        justify-content: center;
        align-items: center;

        i {
          color: #BABABA;

          &.icon-pix-outline {
            font-size: 1.8rem;
          }
        }
      }

      &.selected {
        background: #ff9c00;

        i {
          color: #ff9c00;
        }
      }
    }

    .type {
      padding: 0.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  // @media (max-width: 520px) {
  //   .payment-card-container {
  //     width: 100px;
  //   }
  // }

  // @media (max-width: 420px) {
  //   .payment-card-container {
  //     margin-right: 1rem;
  //     width: 120px;
  //   }
  // }
</style>