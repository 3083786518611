<template>
  <div class="container">
    <header-component />

    <div class="notfound">
      <card-component
        class="card"
        :styles="notFoundData"
      />
    </div>

    <footer-component />
  </div>
</template>

<script>
  import Header from '@/components/base/Header';
  import Footer from '@/components/base/Footer';
  import Card from '@/components/Card';

  export default {
    name: 'NotFound',

    components: {
      'header-component': Header,
      'footer-component': Footer,
      'card-component': Card
    },

    data() {
      return {
        notFoundData: {
          icon: 'icon-checkout-error',
          backgroundIcon: '#b81d5b',
          title: 'Página não encontrada',
          subTitle: 'Entre em contato com o operador e tente novamente!'
        }
      };
    }
  };
</script>


<style lang="scss" scoped>
  .container {
    height: 100vh;
    display: flex;
    flex-direction: column;

    .notfound {
      padding: 2rem;
      display: flex;
      justify-content: center;

      .card {
        margin-top: 1.5rem;
        width: 60rem;
      }
    }
  }
</style>