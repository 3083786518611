<template>
  <div class="container">
    <header-component/>

    <alert-card
      v-if="hasErrorGetDebts"
      :data="errorGetDebtsData"
    />

    <div class="content">
      <div class="items">
        <checkout-header
          :styles="checkoutHeaderData"
          :hasRequest="hasRequest"
        />

        <div v-if="hasRequest" class="default-data-container">
          <card-default-data
            v-for="(item, index) in 3"
            :key="index"
            class="default-card"
          />
        </div>

        <div v-else>
          <div v-if="hasRenegotiation" class="item">
            <div>
              <card-options
                v-for="(item, index) in getAllDebts"
                :key="index"
                :data="item"
              />
            </div>
          </div>

          <card-component
            v-else
            :styles="renegotiationCardEmpty"
          />
        </div>
      </div>
    </div>

    <footer-component/>
  </div>
</template>

<script>
  import Header from '@/components/base/Header';
  import Footer from '@/components/base/Footer';
  import Card from '@/components/Card';
  import OptionCard from '@/components/OptionCard';
  import CheckoutHeader from '@/components/CheckoutHeader';
  import DefaultData from '@/components/DefaultData';
  import { mapGetters } from 'vuex';
  import AlertCard from '@/components/AlertCard';

  export default {
    name: 'Options',

    components: {
      'header-component': Header,
      'footer-component': Footer,
      'alert-card': AlertCard,
      'card-component': Card,
      'checkout-header': CheckoutHeader,
      'card-options': OptionCard,
      'card-default-data': DefaultData
    },

    data() {
      return {
        hasRequest: false,
        hasRenegotiation: false,
        hasErrorGetDebts: false,

        debts: [],

        errorGetDebtsData: {
          text: 'Erro ao listar as propostas!',
          color: '#FF5A5A'
        },

        checkoutHeaderData: {
          fistName: null,
          description: {
            text: 'escolha sua renegociação',
            color: '#333'
          }
        },

        renegotiationCardEmpty: {
          icon: 'icon-information-circle-outline',
          backgroundIcon: '#ff9c00',
          title: 'Nenhuma proposta de renegociação até o momento!',
          subTitle: 'Caso você tenha uma proposta de renegociação, entre em contato para maiores informações.'
        }
      };
    },

    computed: {
      ...mapGetters([
        'getAllDebts',
        'getFirstName'
      ]),
    },

    mounted() {
      this.$store.commit('resetAllDebts');
      this.$store.commit('resetCurrentDebtId');

      this.hasErrorGetDebts = false;
      this.checkoutHeaderData.fistName = this.getFirstName;

      this.fetchData();
    },

    methods: {
      async fetchData() {
        this.hasRequest = true;

        await setTimeout(() => {
          this.$store.dispatch('getDebts')
            .then(res => {
              if (this.$service.isEmptyArr(res.data)) {
                this.hasRenegotiation = false;
              } else {
                this.hasRenegotiation = true;

                res.data.forEach(item => {
                  this.debts.push(this.$service.formatDebt(item));
                });

                let statusOrder = ['PENDING', 'PAID', 'EXPIRED'];

                this.debts.sort((first, second) => {
                  return statusOrder.indexOf(this.$service.toUpperCase(first.debtStatus))
                    - statusOrder.indexOf(this.$service.toUpperCase(second.debtStatus));
                });

                this.$store.commit('setAllDebts', this.debts);
              }
            })
            .catch(() => {
              this.hasErrorGetDebts = true;
            })
            .finally(() => {
              this.hasRequest = false;
            });
        }, 5000);
      }
    }
  };
</script>

<style lang="scss" scoped>
  .container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    .content {
      display: flex;
      justify-content: center;

      .items {
        height: 100%;
        padding: 1.5rem;
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
        width: 60rem;

        .default-data-container {
          .default-card {
            height: 180px;
            border-radius: 20px;
            width: 100%;
            margin-top: 1rem;
          }
        }
      }
    }
  }
</style>