<template>
  <header>
    <router-link
      tag="div"
      class="logo-container"
      to="/debts"
    >
      <img src="@/assets/images/logo-renegotiation-white.svg">
    </router-link>

    <div class="text-container">
      Sistema de pagamentos
    </div>

    <div v-if="!!getToken" class="logout" >
      <button-component
        v-ripple="'rgba(255, 255, 255, 0.2)'"
        styles="secondary"
        iconLeft="icon-logout"
        buttonTextColor="#FFFFFF"
        buttonHoverColor="rgba(255, 255, 255, 0.1)"
        text="Sair"
        @click.native="logout"
      />
    </div>
  </header>
</template>

<script>
  import { mapGetters } from 'vuex';
  import Button from '@/components/Button';

  export default {
    name: 'Header',

    components: {
      'button-component': Button
    },

    computed: {
      ...mapGetters([
        'getToken'
      ])
    },

    methods: {
      logout() {
        this.$store.dispatch('setLogout')
          .then(() =>  {
            this.$router.push('/').catch(() => {});
          });
      }
    }
  };
</script>

<style lang="scss" scoped>
  header {
    z-index: 1;
    background: #aa1428;
    border-bottom: solid 2px #FF9C00;
    padding: 0.5rem 3rem;
    min-height: 85px;
    color: #FFF;
    display: flex;

    .logo-container {
      margin-right: 2rem;
      display: flex;
      align-items: center;

      img {
        width: 9rem;
        position: absolute;
        cursor: pointer;
      }
    }

    .text-container {
      width: 100%;
      justify-content: center;
      align-items: center;
      text-align: center;
      display: flex;
    }

    .logout {
      display: flex;
      align-items: center;
      cursor: pointer;
      font-weight: 600;

      i {
        margin-right: 0.5rem;
        font-size: 14px;
      }
    }
  }

  @media screen and (max-width: 1045px) {
    header {
      padding: 1rem;

      .logo-container {
        position: relative;
      }

      .text-container {
        display: none;
      }

      .logout {
        width: 100%;
        margin-right: 0.5rem;
        justify-content: flex-end;
      }
    }
  }
</style>