<template>
  <div class="summary-container">
    <div v-if="isRetract" class="summary" @click="toggleCard">
      <div class="header">
        <h1>Resumo</h1>

        <div class="icon-container">
          <i v-if="hideCard" class="icon-keyboard-arrow-up"/>
          <i v-else class="icon-keyboard-arrow-down"/>
        </div>
      </div>

      <div v-if="hideCard">
        <div class="service-container">
          <div class="service">
            {{ data.description }}
          </div>

          <div class="price">
            {{ data.formattedDiscountAmount }}
          </div>
        </div>

        <div class="line-break"></div>

        <div class="payment-container">
          <div class="payment">
            Total
          </div>

          <div class="payment number">
            {{ data.formattedDiscountAmount }}
          </div>

          <div v-if="hasInstallments" class="installments-container">
            <div class="installments">
              Forma de pagamento

              <b>{{ paymentData.installments.textOfInstallments }}</b>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else class="summary">
      <div class="header">
        <h1>Resumo</h1>
      </div>

      <div class="service-container">
        <div class="service">
          {{ data.description }}
        </div>

        <div class="price">
          {{ data.formattedDiscountAmount }}
        </div>
      </div>

      <div class="line-break"></div>

      <div class="payment-container">
        <div class="payment">
          Total
        </div>

        <div class="payment number">
          {{ data.formattedDiscountAmount }}
        </div>

        <div v-if="hasInstallments" class="installments-container">
          <div class="installments">
            Forma de pagamento

            <b>
              {{ paymentData.installments.textOfInstallments }}
            </b>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';

  export default {
    name: 'SummaryCardMobile',

    data() {
      return {
        hideCard: true,
        hasInstallments: false,
      };
    },

    props: {
      data: {
        type: Object,
        default() {
          return {
            formattedDiscountAmount: 'R$ 400',
            discountAmount: 400,
            description: 'describe'
          };
        }
      },

      isRetract: {
        type: Boolean,
        default: false,
      }
    },

    computed: {
      ...mapState({
        paymentData: state => state.data,
        isBoleto: state => state.isBoleto,
        isPix: state => state.isPix
      }),
    },

    watch: {
      'paymentData.installments.numberOfInstallments'() {
        this.setInstallments();
      },

      'isBoleto'() {
        this.setPixOrBoletoNumberOfInstallments();
      },

      'isPix'() {
        this.setPixOrBoletoNumberOfInstallments();
      }
    },

    mounted() {
      this.setInstallments();
    },

    methods: {
      toggleCard() {
        this.hideCard = !this.hideCard;
      },

      setInstallments() {
        this.hasInstallments = !!this.paymentData.installments.numberOfInstallments;
      },

      setPixOrBoletoNumberOfInstallments() {
        if (this.isBoleto || this.isPix) {
          this.paymentData.installments.numberOfInstallments = 1;
          this.paymentData.installments.textOfInstallments = '1 x de ' + this.data.formattedDiscountAmount;
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  .summary-container {
    margin-bottom: 2rem;

    .summary {
      background: #efefef;
      border-radius: 20px;
      font-size: 1.5rem;
      padding: 1.5rem;

      .header {
        display: flex;
        align-items: center;

        .icon-container {
          width: 100%;
          display: flex;
          justify-content: flex-end;

          i {
            font-size: 12px;
          }
        }

        h1 {
          font-size: 1.2rem;
        }
      }

      .line-break {
        border-bottom: 1px solid #b9b9b9;
      }

      .service-container {
        font-size: 1rem;
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;

        .price {
          padding: 0.3rem;
          margin-top: 0.7rem;
        }
      }

      .payment-container {
        margin-top: 1.5rem;
        font-size: 1.2rem;

        .payment {
          font-weight: 600;
          display: inline;
        }

        .payment.number {
          position: relative;
          float: right;
        }

        .installments-container {
          display: flex;
          justify-content: flex-end;
          font-size: 20px;
        }

        .installments {
          color: #6d6f6f;
          font-size: 1rem;
          margin-top: 1rem;
        }
      }
    }
  }

  @media (max-width: 1199px) {
    .summary-container {
      .summary {
        font-size: 1.2rem;

        .header {
          h1 {
            font-size: 20px;
          }
        }

        .service-container {
          .price {
            margin-top: 0;
          }
        }

        .payment-container {
          margin-top: 1rem;
          font-size: 22px;

          .payment {
            display: inline;
          }

          .installments {
            font-size: 1rem;
          }
        }
      }
    }
  }

  @media (max-width: 1045px) {
    .summary-container {
      .summary {
        .service-container {
          font-size: 16px;
        }

        .installments-container {
          .installments {
            font-size: 14px;
          }
        }
      }
    }
  }

  @media (max-width: 420px) {
    .summary-container {
      .summary {
        font-size: 1rem;

        .header {
          h1 {
            font-size: 20px;
          }
        }

        .payment-container {
          .payment {
            font-size: 1.2rem;
          }

          .installments {
            b {
              font-size: 1rem;
            }
          }
        }
      }
    }
  }
</style>