<template>
  <card-default-data
    v-if="hasRequest"
    class="default-data"
  />

  <div v-else>
    <div class="header">
      <div class="description" :style="{ color: styles.description.color }">
        <div>
          <h1 v-if="hasFistName()">
            Olá

            <span>{{ styles.fistName }},</span>
          </h1>

          <h1>{{ styles.description.text }}</h1>
        </div>

        <h1 v-if="!!styles.contractId" class="contract">
          Contrato

          <b>{{styles.contractId}}</b>
        </h1>
      </div>
    </div>

    <div class="line-break"></div>
  </div>
</template>

<script>
  import DefaultData from '@/components/DefaultData';

  export default {
    name: 'CheckoutHeader',

    props: {
      styles: {
        type: Object,
        default() {
          return {
            description: {
              text: 'Description',
              color: '#aa1428'
            },
            contractId: '202020'
          };
        }
      },

      hasRequest: {
        type: Boolean,
        default: false
      }
    },

    components: {
      'card-default-data': DefaultData
    },

    methods: {
      hasFistName() {
        return this.styles.fistName != undefined;
      }
    }
  };
</script>

<style lang="scss" scoped>
  .default-data {
    width: 100%;
    height: 46px;
    border-radius: 10px;
    margin-bottom: 2rem;
  }

  .header {
    padding: 0 1rem;

    h1 {
      font-size: 1.2rem;
      display: inline;
    }

    .description {
      display: flex;

      h1 {
        span {
          color: #ff9c00;
          text-transform: capitalize;
          margin-right: 0.5rem;
        }
      }

      .contract {
        color: #6d6f6f;
        font-weight: 300;
        margin-left: auto;
      }
    }
  }

  .line-break {
    border-bottom: 3px solid #ff9c00;
    margin-top: 0.5rem;
    margin-bottom: 2rem;
    padding: 0 1rem;
  }

  @media (max-width: 1300px) {
    .header {
      padding: 0;
      display: flex;
      flex-direction: column;

      h1 {
        font-size: 20px;
      }

      .contract {
        float: none;
        font-size: 20px;
      }
    }

    .line-break {
      margin-bottom: 1.5rem;
    }
  }

  @media (max-width: 678px) {
    .header {
      .contract {
        h1 {
          font-weight: 300;
        }
      }

      h1 {
        font-size: 1.2rem;
      }
    }
  }

  @media (max-width: 440px) {
    .header {
      .description {
        flex-direction: column;

        .contract {
          margin-left: 0;
          font-size: 16px;
        }
      }
    }
  }
</style>